'use strict';

import React from 'react';
import AOS from 'aos';
import $ from 'jquery';
import translate from "../translate";

import paths from '../../lib/paths';
import ContactCTA from '../contactCTA';
import arrow from '../../assets/icon/arrow.svg';
import SectionDemo from "../section-demo";
import Parse from "parse";
import _ from "lodash";
import SectionDiscoverPlattform from "../section-discover-plattform";
import arrowIcon from '../../assets/icon/arrow-white.svg';
import SectionDiscoverNextSteps from "../section-discover-next-steps";




class Applications extends  React.Component{
    constructor(props) {
        super(props);

        this.state = {
            activeItemIndex: 0,
            teamMembers: []
        }
    }

    async componentDidMount() {
        await this.getTeamMembers(() => {
            AOS.init({
                duration: 2000,
                delay: 1000
            });
            const teamSlider = $('.team__slider');
            teamSlider.slick({
                speed: 800,
                rows: 100,
                variableWidth: true,
                infinite: false,
                slidesPerRow: 4,
                swipeToSlide: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesPerRow: 3
                        }
                    },
                    {
                        breakpoint: 950,
                        settings: {
                            slidesPerRow: 2
                        }
                    },
                    {
                        breakpoint: 670,
                        settings: {
                            slidesPerRow: 1
                        }
                    }
                ]
            });
        });
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }


    render() {
        const {t} = this.props;
        let language = this.props.i18n.resolvedLanguage;

        return (
            <>
                <section className='title-section first-screen'>
                    <div className='wrapper'>
                        <div className='title-section__main-info'>
                            <h2>
                                {t('Ein $cleveres System für Ihre $Nichtwohngebäude', {delay: false})}
                            </h2>
                        </div>
                    </div>
                </section>
				<section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit" style={{height: 600}}    loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit" style={{height: 600}} loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain"                    loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-700" alt="" />
                    </div>
                </section>
				<div  id="del-fixed" className="wrapper" data-aos-id="del-fixed" data-aos="del-fixed" data-aos-offset="0" style={{marginTop: 60}}>
                    <h3 className="no-margin">
                        {t('Hier sind die $Anwendungen mit hohem $Energiesparpotenzial')}
                    </h3>
                </div>
                
                <section className='faq'>
                    <div className='wrapper'>
                        <ul className='faq__list'>
                            <li id='first' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Schulhäuser')}</h3>
                                <div className='faq__answer'>
                                    {t('Temperatur und Belüftung wird in Schulhäusern üblicherweise zentral gesteuert. Dadurch kann die Temperatur nicht individuell pro Raum reguliert werden und die Luftqualität ist Studien zufolge oftmals ungenügend. Mit unseren cleveren Thermostaten stellen Sie jedoch jederzeit und vollautomatisch ein optimales Lernklima für Ihre Gebäudebenutzer sicher und sparen zusätzlich Energie, indem nur dort geheizt wird, wo es nötig ist.')}
                                </div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('first')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                            <li id='second' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Büros & Verwaltung')}</h3>
                                <div className='faq__answer'>
                                    {t('In Büro- und Verwaltungsgebäuden kann durch eine bedarfsgerechte Temperatursteuerung nicht nur Energie eingespart, sondern auch der Komfort gewährleistet werden. Je nach Aktivitätsniveau der Raumbenutzer kann die Temperatur über einen Klick individuell angepasst werden. Zudem kann die Temperatursteuerung der Sitzungszimmer mit Kalenderdaten verbunden werden, damit nur dann geheizt wird, wenn es nötig ist. Sensoren in Grossraumbüros und Sitzungszimmern garantieren zusätzlich höchste Luftqualität und unterstützen beim richtigen Belüften der Räume')}
                                </div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('second')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                            <li id='third' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Spitäler & Pflegeheime')}</h3>
                                <div className='faq__answer'>
                                    {t('Viele Krankheiten werden durch die Luft übertragen. Dank unseren Thermostate kann die Luftqualität jederzeit überwacht werden, um die Gesundheit der Patient*innen sicherzustellen. Neben den stetig beheizten Patientenzimmern, lässt sich mit unseren cleveren Thermostaten zudem die optimale und energieeffizienteste Raumtemperatur für Gemeinschaftsräume und Kantinen finden, ohne an Komfort einzubüssen. ')}
									<br></br>
								</div>
								<div className='faq__open-btn' onClick={() => this.openDescriptionBlock('third')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
							<li id='fourth' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Hotels & Resorts')}</h3>
                                <div className='faq__answer'>
                                    {t('Der Gästekomfort steht an erster Stelle. In Gemeinschaftsräumen, Empfang und Durchgängen erlauben unsere cleveren Sensoren jederzeit ein aktuelles Abbild des Raumklimas und unterstützen dabei, Lüftungsanlagen zu optimieren und so deren Energieverbrauch zu reduzieren. In Ergänzung ermöglichen unsere smarten Thermostate die vollautomatische Absenkung der Temperatur in ungenutzten Räumen und ebenso das Aufheizen der Zimmer vor der Ankunft der Gäste.')}
								</div>
								<div className='faq__open-btn' onClick={() => this.openDescriptionBlock('fourth')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
							<li id='fifth' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Denkmalgeschützten Gebäuden')}</h3>
                                <div className='faq__answer'>
                                    {t('Denkmalgeschützte Gebäude schließen oft viele energetische Sanierungsmöglichkeiten aus oder erhöhen die Kosten dafür erheblich. Unsere intelligenten Thermostate bieten eine sofortige Möglichkeit zur Energieeinsparung, ohne dass bauliche Maßnahmen erforderlich sind und ohne Einbußen beim Komfort.')}
								</div>
								<div className='faq__open-btn' onClick={() => this.openDescriptionBlock('fifth')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
				<SectionDiscoverNextSteps/>
				<SectionDemo/>
            </>
        )
    }
}

export default translate(Applications);
'use strict';

import React from 'react';
import * as db from './structure';
import Parse from 'parse';
import _ from 'lodash';

/**
 *
 * @param {Parse.Object} parseObject
 * @return {{__type: string, className: string, objectId: string}}
 */
export const toPointer = (parseObject) => {
    let className = parseObject.className;
    return {
        '__type': 'Pointer',
        'className': className,
        'objectId': parseObject.id
    };
};

/**
 * Get pointer from parse object id.
 *
 * @param parseObjectId
 * @param className
 * @return {{__type: string, className: *, objectId: *}}
 */
export const toPointerFromId = (parseObjectId, className) => {
    return {
        '__type': 'Pointer',
        'className': className,
        'objectId': parseObjectId
    };
};

/**
 *
 * @param err
 * @param propsContext
 * @returns {*}
 */
export const manageError = (err, propsContext) => {
    console.error(err);

    if(err.code === 100){//Connection timeout
        if(propsContext) {
            setTimeout(() => {
                propsContext.dispatch(deactivateConnectionTimeoutError());
            }, 5000);
            return propsContext.dispatch(activateConnectionTimeoutError());
        }
    }

    if(err.code === Parse.Error.INVALID_SESSION_TOKEN){//Connection timeout
        Parse.User.logOut();
        window.location= '/login';
    }
};

/**
 *
 * @param day
 * @param room
 * @param home
 * @return {Promise<object>}
 */
export const calculateEnergy = (day, room, home) =>
    Parse.Cloud.run('energyRoom', {roomId: room.id, day: day})
        .then((averageTemps) => {
            let tempOutAverage;
            if (averageTemps.tempOutAverage < 10) {
                tempOutAverage = averageTemps.tempOutAverage;
            } else {
                tempOutAverage = 5;
            }
            let roomWallArea = Math.sqrt(room.get(db.Room.AREA)) * 2 * 3;
            const roomVolume = room.get(db.Room.AREA) * room.get(db.Room.HEIGHT);
            const airLoss = 0.68 * 0.343; // q(1-rec)*c_air
            let energyReal = (averageTemps.tempRoomAverage - tempOutAverage) *
                (home.get(db.Home.U_VALUE) * roomWallArea + roomVolume * airLoss) * 24 * 0.001;
            let refTemp = averageTemps.tempMax;
            if (refTemp > 24) {
                refTemp = 24
            } else if (refTemp < 22) {
                refTemp = 22
            }

            if (refTemp < averageTemps.tempRoomAverage) {
                refTemp = averageTemps.tempRoomAverage
            }

            let energyWithout = (refTemp - tempOutAverage) *
                (home.get(db.Home.U_VALUE) * roomWallArea + roomVolume * airLoss) * 24 * 0.001;

            let annualRequirement = (23 - 10) *
                (home.get(db.Home.U_VALUE) * home.get(db.Home.AREA_WALL) + home.get(db.Home.VOLUME) * airLoss) *
                24 * 0.001 * 365;
            let energySaved = (energyWithout - energyReal) / energyReal * 100;
            let coReduced = (energyWithout - energyReal) * 73.7 * 0.0000036 * 1000;
            // 0.0000036 = kWh to TJoule  // 1000 = ton to Kg 73.7 coEmission oil --> to link with home system

            refTemp = isNaN(refTemp) ? refTemp : null;
            annualRequirement = isNaN(annualRequirement) ? null: annualRequirement;
            energyWithout = isNaN(energyWithout) ? null : energyWithout;
            energyReal = isNaN(energyReal) ? null : energyReal;
            energySaved = isNaN(energySaved) ? null : energySaved;
            coReduced = isNaN(coReduced) ? null : coReduced;

            const energy = {
                refTemp,
                annualRequirement,
                energyWithout,
                energyReal,
                energySaved,
                coReduced,
            };

            return energy
        });


export const floorNameFromNumber = (floorNumber) => {

    let floorNameMap = {
        '-1': 'Basement',
        0: 'Ground floor',
        1: 'First floor',
        2: 'Second floor',
        3: 'Third floor',
        4: 'Fourth floor',
        'undefined': 'undefined'
    };

    return floorNameMap[floorNumber];
};



export const calculateMeanTempAndHumidity = (rooms) =>{
    if(!rooms) return;

    let meanTemp = 0, meanHumidity = 0, numRoom = rooms.length;
    let countTemp = 0, countHum = 0;

    rooms.forEach(room => {
        if(room.tempRoom != null) {
            meanTemp += parseFloat(room.tempRoom);
            countTemp++;
        }
        if(room.humRoom != null) {
            meanHumidity += parseFloat(room.humRoom);
            countHum++;
        }
    });

    meanTemp = meanTemp / countTemp;
    meanHumidity = meanHumidity / countHum;

    if(!meanTemp || isNaN(meanTemp)) return;

    return {
        meanTemp: meanTemp.toFixed(1),
        meanHumidity: meanHumidity.toFixed(1)
    }
};


/**
 * Split rooms by floor.
 *
 * @param rooms
 * @return {object}
 */
export const splitRoomByFloor = (rooms) => {
    if(!rooms) return {};

    let floors = {};

    rooms.forEach(room => {
        let floor = room.get(db.Room.FLOOR);

        if(!floors[floor]) {
            floors[floor] = [];
        }

        floors[floor].push(room);
    });

    floors = _(floors).toPairs().sortBy(0).fromPairs().value();

    return floors;
};


export const resizeImage = (imageUrl, height=200, quality=100) => {
    return imageUrl.replace('https://cdn.buttercms.com/', `https://fs.buttercms.com/resize=height:${height}/quality=value:${quality}/`);
};


const replaceAll = function(text, search, replacement) {
    return text.split(search).join(replacement);
};

export const replaceCO2 = (text) => replaceAll(text,' CO2', ' CO<sub>2</sub>');
export const replace1mioco2 = (text) => {
    text = replaceAll(text,'1mioco2.ch', '<a href="https://1mioco2.ch" target="_blank" class="sliding-link">1mioco2.ch</a>');
    text = replaceAll(text,'1MioCO2.ch', '<a href="https://1mioco2.ch" target="_blank" class="sliding-link">1MioCO2.ch</a>');
    //text = replaceAll(text,'1 Mio. Kilogramm CO<sub>2</sub>', '<a href="https://1mioco2.ch">1 Mio. Kilogramm CO<sub>2</sub></a>');

    return text;
};

export const htmlT = function (t){

    return function (text) {
        let tText = t(text);

        if(tText.includes(' CO2') || tText.includes('1mioco2.ch') || tText.includes('1MioCO2.ch')){
            let html = replaceCO2(tText);

            html = replace1mioco2(html);

            return <span dangerouslySetInnerHTML={{__html: html}}></span>
        }

        if(isHtml(tText)){
            return <span dangerouslySetInnerHTML={{__html: tText}}></span>
        }

        return tText;
    }
};


const isHtml = function(string){
    const htmlTags = require('html-tags');

    const basic = /\s?<!doctype html>|(<html\b[^>]*>|<body\b[^>]*>|<x-[^>]+>)+/i;
    const full = new RegExp(htmlTags.map(tag => `<${tag}\\b[^>]*>`).join('|'), 'i');

    return basic.test(string) || full.test(string);
};
const paths = require('./paths');
const Parse = require('parse');

let servers = {
    stage: 'https://serverstage.simplyhome.tech',
    live: 'https://server.cleveron.ch',
    local: 'http://localhost:1337'
};

export function initializeParse() {
    Parse.initialize('NjSUT8HxvCz706ldcwUn');
    Parse.serverURL = `${servers.live}/parse`;

    return Promise.resolve();
}

/**
 *
 * @param history
 * @param path
 * @returns {Parse.Object || null}
 */
export function redirectIfNotLogged(history, path){
    let user = Parse.User.current();
    Parse.Session.current().catch(console.error);

    if(user) return user;

    history.push(path || paths.login);
}

/**
 *
 * @param history
 * @param path
 * @returns {Parse.Object || null}
 */
export function redirectIfLogged(history, path){
    let user = Parse.User.current();

    if(user) return history.push(path || paths.dashboard);
}





'use strict';

import React from 'react';
import AOS from "aos";

import translate from "../translate";
import ContactCTA from "../contactCTA";

class AboutProject extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });
    }

    render() {
        const {t} = this.props;

        return (
            <main className="main">
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('eine SWISS MADE Technologie mit der Sie $Heizkosten $sparen und die $Luftqualität $überwachen können', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big"    loading={'lazy'} src="/assets/product/full-system4x.jpg" alt="" />
                        <img className="img img--medium" loading={'lazy'} src="/assets/product/full-system2x.jpg" alt="" />
                        <img className="img img--small"  loading={'lazy'} src="/assets/product/full-system2x.jpg" alt="" />
                    </div>
                </section>
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h3 data-aos="fade-left">
                                    Supsi Project
                                </h3>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <p>{t(`Lorem ipsum
                                            dolor sit
                                            amet, cons ectetuer adipiscing elit, sed diam nonummy nibh
                                            euismod tincidunt ut laoreet dolore
                                            Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                                            nibh
                                            euismod tincidunt ut laoreet dolore
                                            Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                                            nibh
                                            euismod tincidunt ut laoreet dolore`)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big"    loading={'lazy'} src="/assets/air-quality/full-img4x.jpg" alt="" />
                        <img className="img img--medium" loading={'lazy'} src="/assets/air-quality/full-img2x.jpg" alt="" />
                        <img className="img img--small"  loading={'lazy'} src="/assets/air-quality/full-img2x.jpg" alt="" />
                    </div>
                </section>
                <section className="sidebar-section bg-gray">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h3 data-aos="fade-left">
                                    1 {t('mio')} CO2
                                    Campaign
                                </h3>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <p>{t(`Lorem ipsum
                                            dolor sit
                                            amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt
                                            ut
                                            laoreet dolore
                                            Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                                            nibh
                                            euismod tincidunt ut laoreet dolore
                                            Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                                            nibh
                                            euismod tincidunt ut laoreet dolore`)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ContactCTA />
            </main>
        );
    }
}

export default translate(AboutProject);
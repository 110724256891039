'use strict';

import React from 'react';

import translate from '../translate';
import paths from '../../lib/paths'
import result from '../../assets/icon/corporate/result.svg';
import contact from '../../assets/icon/corporate/contact.svg';
import calculation from '../../assets/icon/corporate/calculation.svg';
import pilot from '../../assets/icon/corporate/pilot.svg';
import portfolio from '../../assets/icon/corporate/portfolio.svg';
import optimisation from '../../assets/icon/corporate/optimisation.svg';

class SectionProjectSteps extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <section className="text-section">
            <div className="wrapper">
                <div className="text-section__row text-section__row--col-3">
                    <div className="text-section__item">
                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                             data-aos-delay="50">
                            <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                            <img src={contact} loading={'lazy'} alt="" className="text-section__icon" />
                        </div>
                            <h4>{t('Kontaktaufnahme')}</h4>
                            <div className="text-section__info">
                                {t(`Nehmen Sie Kontakt mit unseren Experten auf. Sie können uns direkt über unsere Website erreichen. Füllen Sie das untenstehende Formular aus.`)}
                            </div>
                        </div>
                    </div>
                    <div className="text-section__item">
                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                             data-aos-delay="50">
                            <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                            <img src={calculation} loading={'lazy'} alt="" className="text-section__icon" />
                        </div>
                            <h4>{t('Berechnung Potenzial')}</h4>
                            <div className="text-section__info">
                                {t(`Gemeinsam evaluieren wir Ihre Bedürfnisse und Ihre Einsparungspotential, um eine maßgeschneiderte Lösung für Sie zu entwickeln. Dabei berechnen wir Ihr Einsparpotenzial bei den CO2-Emissionen und den Kosten.`)}
                            </div>
                        </div>
                    </div>
                    <div className="text-section__item">
                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                             data-aos-delay="50">
                            <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                            <img src={pilot} loading={'lazy'} alt="" className="text-section__icon" />
                        </div>
                            <h4>{t('Pilotphase')}</h4>
                            <div className="text-section__info">
                                {t(`In der Regel statten wir ein oder mehrere Gebäude für eine Heizperiode mit unserem Cleveron-System aus, um Ihnen die Funktionsweise und das Einsparpotenzial direkt an Ihren Gebäuden aufzuzeigen.`)}
                            </div>
                        </div>
                    </div>
                    <div className="text-section__item">
                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                             data-aos-delay="50">
                            <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                            <img src={result} loading={'lazy'} alt="" className="text-section__icon" />
                        </div>
                            <h4>{t('Evaluation der Pilotphase')}</h4>
                            <div className="text-section__info">
                                {t(`Nach der Pilotphase evaluieren wir gemeinsam die Erfahrungen mit dem Cleveron-System und legen das weitere Vorgehen fest.`)}
                            </div>
                        </div>
                    </div>
                    <div className="text-section__item">
                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                             data-aos-delay="50">
                            <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                            <img src={portfolio} loading={'lazy'} alt="" className="text-section__icon" />
                        </div>
                            <h4>{t('Roll-out Phase')}</h4>
                            <div className="text-section__info">
                                {t(`Nach erfolgreicher Pilotphase statten wir die restlichen Gebäude in Ihrem Gebäudebestand mit dem Cleveron-System aus.`)}
                            </div>
                        </div>
                    </div>
                    <div className="text-section__item">
                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                             data-aos-delay="50">
                            <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                            <img src={optimisation} loading={'lazy'} alt="" className="text-section__icon" />
                        </div>
                            <h4>{t('Kontinuierlicher Optimierung')}</h4>
                            <div className="text-section__info">
                                {t(`Wir begleiten all unsere Kunden weiterhin auf ihrem Weg zu mehr CO2-Einsparungen und optimieren unser System kontinuierlich.`)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        )
    }
}

export default translate(SectionProjectSteps);
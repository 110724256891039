'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import i18next from './../i18n';
import {htmlT} from '../../lib/util';


function t2(t){
    return function t3(label, options){

        let delay = options?.delay ?? false;

        let words = t(label).split(' ');

        let translatedLabel = words
                .map((word, i) => {
                    if(delay){
                        if(word.startsWith('$'))
                            return <span key={i} className='decor-color' data-aos='fade' data-aos-delay={(i+1) * 50}>{word.replace('$', '')} </span>
                        return <span key={i} data-aos='fade' data-aos-delay={(i+1) * 50}>{word} </span>;
                    } else {
                        if(word.startsWith('$'))
                            return <span key={i} className='decor-color'>{word.replace('$', '')} </span>;

                        if(word === '<br/>') return <br key={i}/>;

                        if(word.startsWith('<br/>'))
                            return <span key={i}><br/>{word.replace('<br/>', ' ')} </span>;

                        if(word.endsWith('<br/>'))
                            return <span key={i}>{word.replace('<br/>', ' ')} <br/> </span>;

                        if(word === '<br/>') return <br key={i}/>;

                        if(word.includes('<sup>'))
                            return <sup key={i}>{word.replace('<sup>', '').replace('</sup>', '')} </sup>;

                        if(words[i + 1] && words[i + 1].startsWith('<sup>')) return word;

                        return word + ' ';
                    }
                });

        let allStrings = translatedLabel.filter(label => typeof label === 'string' || label instanceof String).length
            === translatedLabel.length

        if(allStrings) return translatedLabel.join('');

        return translatedLabel;
    }
}

// import fetch from
const translate = (WrappedComponent) => {

    class TranslatedComponent extends React.Component {

        static get component(){
            return WrappedComponent;
        }

        constructor(props) {
            super(props);
        }


        render() {
            if (!this.props.i18n.isInitialized) {
                return <div className="loader-container"><span className="loader loader--medium"></span></div>;
            }

            return React.cloneElement(<WrappedComponent {...this.props} />, {
                t: t2(this.props.t)
            })
        }
    }

    TranslatedComponent.propTypes = {
        i18n: PropTypes.object
    };

    return withTranslation(null, {withRef: true})(TranslatedComponent);
};

export default translate;


import React from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';
import {cookiePopup} from '../../lib/ui-utils';

 class CookiePrivacyPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        cookiePopup();
    }

    render() {
        const {t} = this.props;

        return <div id="cookies_privacy_popup" className="hide">
            <h5 className="head">{t('This website uses cookies')}</h5>
            <p className="body">{t("We use them to give you the best experience. If you continue using our website, we'll assume that you are happy to receive all cookies on this website.")}</p>
            <button className="action-accept">{t('Accept')}</button>
            <button className="action-decline">{t('Decline')}</button>
        </div>;
    }
}

CookiePrivacyPopup.propTypes = {
    t: PropTypes.func
};

export default translate(CookiePrivacyPopup);
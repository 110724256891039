'use strict'
import React from 'react';
import $ from 'jquery';
import AOS from 'aos';

import paths from '../../lib/paths';
import translate from "../translate";
import SectionHowMuchImpact from "../section-how-much-impact";
import logo from '../../assets/logo.svg';

import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import person from '../../assets/icon/product/person.svg';
import weather from '../../assets/icon/product/weather.svg';
import calendar from '../../assets/icon/product/calendar.svg';
import schema from '../../assets/icon/product/schema.svg';
import monitor from '../../assets/icon/product/room-monitor.svg';
import smart from '../../assets/icon/corporate/smart-building.svg';
import portfolio from '../../assets/icon/product/portfolio.svg';
import machine from '../../assets/icon/product/machine-learning.svg';
import SectionDiscoverApplication from '../section-discover-application';
import SectionPricingInformation from '../section-pricing-information';
import SectionBookDemo from "../section-book-meeting";
import SectionDemo from "../section-demo";
import BookDemoModal from "../book-demo-modal";
import SectionAdvantagesInformation from "../section-advantages-information";
import SectionDiscoverDevices from "../section-discover-devices";


class Plattform extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            openDemoModal: false
        }
    }

    componentDidMount() {
        let getParams = Object.fromEntries(new URLSearchParams(window.location.search));

        if(getParams.demoModalOpen === 'true'){
            this.setState({openDemoModal: true});
        }

        window.scroll(0, 0)
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        let offsetAOS = $(window).height();
        $('[data-aos-id="fixed"]').attr('data-aos-offset', offsetAOS);
        $('[data-aos="active"]').attr('data-aos-offset', (offsetAOS / 2));
    }

    render() {
        const {t} = this.props;
        let language = this.props.i18n.resolvedLanguage;
        let videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_DE.mp4';
        if(language === 'fr'){
            videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_FR.mp4'
        }

        return (
            <main className='main'>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('Die $clevere Plattform für eine effiziente $Heizsteuerung', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit"     style={{height: 1000}}  loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit"   style={{height: 1000}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain"                      loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-700" alt="" />
                    </div>
                    <div className="big-img__text-block">
                        <h3 data-aos="fade-right" data-aos-delay="650" >{t('')}</h3>
                    </div>
                </section>
                <section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row text-section__row--col-3">
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="50">
                                    <h4>{t('Autonome und intelligente Heizungssteuerung')}</h4>
                                    <div className="text-section__info">
                                        {t(`Selbstständige Erfassung der Raumnutzung und automatische Anpassung der Heizleistung zur Optimierung des Energieverbrauchs.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="150">
                                    <h4>{t('Benutzerzentrierte Ansatz')}</h4>
                                    <div className="text-section__info">
                                        {t(`Energieeinsparung ohne Komfort einzubüssen. Wir stellen den Nutzer in den Mittelpunkt, indem wir ihm die Möglichkeit geben, in Echtzeit mit dem System zu interagieren.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="300">
                                    <h4>{t('Modularität und Integration')}</h4>
                                    <div className="text-section__info">
                                        {t(`Unser System ist modular aufgebaut und verfügt über offene Schnittstellen. Dadurch können andere Überwachungs- und Steuerungssysteme einfach integriert werden.`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<section className="title-section">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('Eine $intuitive, auf den $Menschen $zentrierte und $datengetriebene Plattform', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
				<section className="message-section">
					<div className="message-section__row">
						<div className="img-block">
							<img className="img img--big"    src="https://ik.imagekit.io/sh/cleveron_ch/conponent-3-2x_OUCU5NFM9.jpg?tr=w-1600" loading="lazy" alt="" />
							<img className="img img--medium" src="https://ik.imagekit.io/sh/cleveron_ch/conponent-3-2x_OUCU5NFM9.jpg?tr=w-1400" loading="lazy" alt="" />
							<img className="img img--small"  src="https://ik.imagekit.io/sh/cleveron_ch/conponent-3-2x_OUCU5NFM9.jpg?tr=w-700"  loading="lazy" alt="" />
						</div>
						<div className="text-block">
							<div className="container">
                                        <h3>
                                            CLEVER Cloud & Webapp
                                        </h3>
                                        <div className="text-block__info">
                                            {t(`Unsere speziell entwickelte Webanwendung für Nichtwohngebäude erfasst automatisch die Raumnutzung und reguliert die Heizleistung entsprechend. Durch die Berücksichtigung der Anwesenheit von Personen im Gebäude ermöglicht sie Energieeinsparungen, ohne den Komfort zu beeinträchtigen. Dank unserer APIs lässt sich unsere Webanwendung problemlos in andere Systeme integrieren.`)}
                                        </div>
                                        <ul className="components__list">
                                            <li>
                                                {t('Intuitive Bedienung')}
                                            </li>
											<li>
                                                {t('Flexible Verwaltung jedes einzelnen Raums')}
                                            </li>
                                            <li>
                                                {t('Keine Software erforderlich - alles über die Cloud')}
                                            </li>
                                        </ul>
                                        <a href={paths.links.thermoDatasheet} className="btn btn-green"
                                           style={{marginTop: "30px", fontSize: "24px"}}>
                                            {t('Demo Zugang anfragen')}
                                        </a>
                                    </div>
						</div>
					</div>
				</section>
				<div className="wrapper" id="del-fixed" data-aos-offset="0">
                    <h3 className="no-margin">
                        {
                            t('Ihre $Vorteile mit unserer $cleveren Webanwendung')
                        }
                    </h3>
                </div>
				<section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row">
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={smart} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Zentrale Steuerung')}</h4>
                                    <div className="text-section__info">
                                        {t(`Dank unserer cloudbasierten Plattform haben Sie die Möglichkeit, alles zentral zu steuern – von einzelnen Räumen bis hin zum gesamten Gebäudeportfolio.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={machine} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('KI-gestützter, selbstlernender Algorithmus')}</h4>
                                    <div className="text-section__info">
                                        {t(`Unser System erkennt Muster in der Gebäudenutzung und passt die optimale Heizleistung automatisch an. Dank des selbstlernenden Algorithmus verbessert sich das System kontinuierlich.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={calendar} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Kalender und Belegungspläne')}</h4>
                                    <div className="text-section__info">
                                        {t(`Durch unsere offenen Schnittstellen können Kalender und Belegungspläne nahtlos integriert werden, wodurch sich die Heizleistung automatisch an die Raumnutzung anpasst.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={graphic} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('ESG-Kennzahlen')}</h4>
                                    <div className="text-section__info">
                                        {t(`Dank Kennzahlen zum Energieverbrauch und zur Luftqualität können Sie Ihr ESG-Rating in den Bereichen Energieeffizienz und Arbeitsplatzgesundheit verbessern.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={monitor} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Übersicht über die aktuelle Raumtemperatur')}</h4>
                                    <div className="text-section__info">
                                        {t(`Mit nur einem Klick können Sie die Temperatur jedes einzelnen Raumes überprüfen und zusätzliche Nutzungsdaten abrufen.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={portfolio} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Überblick auf das ganze Portfolio')}</h4>
                                    <div className="text-section__info">
                                        {t(`Dank der intuitiven Plattform, haben Sie ganz einfach einen Überblick über Ihre Gebäudeportfolio und können dort optimieren wo es am meisten Sinn ergibt.`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<SectionDiscoverDevices/>
				<section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <div className="logo sidebar-section__logo">
                                    <img src={logo} loading={'lazy'} alt="" className="logo__img" />
                                </div>
                                <img className="sidebar-section__client-logo" loading={'lazy'} alt=""/>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <h3>
                                            {t('Eine $Plattform, die entwickelt wurde, um mit anderen Systemen $integriert zu werden')}
                                        </h3>
                                        <p>
                                            {t('Im Gegensatz zu den meisten Automatisierungssystemen wurde unsere Plattform mit offenen Schnittstellen entwickelt. Unsere API ermöglicht es Ihnen, verschiedene Systeme wie ESG-Kennzahlenmanagementplattformen oder Lüftungssteuerungssysteme nahtlos zu integrieren.')}
                                        </p>
                                        <a href="https://swisscom.cleveron.ch/" target={'_blank'} className="btn">{t('Erfahren Sie mehr')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				
                <SectionDemo/>

            </main>
        )
    }
}

export default translate(Plattform);

'use strict';

import React from 'react';
import AOS from "aos";
import swal from "sweetalert";
import administrativeIcon from '../../assets/images/administrative.svg';
import schoolIcon from '../../assets/images/school.svg';
import industriesIcon from '../../assets/images/industries.svg';
import hotelsIcon from '../../assets/images/hotels.svg';
import administrativeIconSelected from '../../assets/images/administrative-selected.svg';
import schoolIconSelected from '../../assets/images/school-selected.svg';
import industriesIconSelected from '../../assets/images/industries-selected.svg';
import hotelsIconSelected from '../../assets/images/hotels-selected.svg';
import cloudIcon from '../../assets/images/cloud.svg';
import currencyIcon from '../../assets/images/currency.svg';
import chartIcon from '../../assets/images/chart.svg';
import chfIcon from '../../assets/icon/calculator/money-calc.svg';
import translate from "../translate";
import SectionDemo from '../section-demo';
import Parse from "parse";
import _ from 'lodash';
import SectionDemoModalGreenButton from "../section-demo-modal-green-button";
import SectionCallModalGreenButton from "../section-call-modal-green-button";
import SectionResultCalc from '../section-result-calc';

const request = require('superagent');
const isValidEmail = require('is-valid-email');

let consumptionPerKWh = {
    'gas': 0.1293,
    'heat-pump': 0.15,//https://cliq.zoho.eu/chats/1203826479778755110/messages/1654078470266_87184057202810
    'heating-oil': 0.1402,
    'district-heating': 0.15, //https://cliq.zoho.eu/chats/1203826479778755110/messages/1654082052931_87403104117609
    'wood': 0.972,
    'electricity': 0.18
};

let yearConsumptionMap = {
    1970: 150,
    1990: 130,
    2000: 100,
    9999: 80
};

let numberDevicePerSquareMeter = {
    'administrative-building': {
        thermo: 30,
        sense: 100
    },
    'school': {
        thermo: 40,
        sense: 140
    },
    'industries': {
        thermo: 35,
        sense: 120
    },
    'hotel': {
        thermo: 25,
        sense: 140
    },
};

let savingsPerThermokWh = {
    1970: {
        25: 750,
        30: 900,
        35: 1050,
        40: 1200
    },
    1980: {
        25: 650,
        30: 780,
        35: 910,
        40: 1040
    },
    1990: {
        25: 500,
        30: 600,
        35: 700,
        40: 800
    },
    2000: {
        25: 500,
        30: 600,
        35: 700,
        40: 800
    },
    2010: {
        25: 500,
        30: 600,
        35: 700,
        40: 800
    }
};

let savingsPerSensorkWh = {
    1970: {
        60: 450,
        80: 600,
        100: 750,
        120: 900,
        140: 900,
    },
    1980: {
        60: 390,
        80: 520,
        100: 650,
        120: 780,
        140: 780,
    },
    1990: {
        60: 300,
        80: 400,
        100: 500,
        120: 600,
        140: 600
    },
    2000: {
        60: 300,
        80: 400,
        100: 500,
        120: 600,
        140: 600
    },
    2010: {
        60: 300,
        80: 400,
        100: 500,
        120: 600,
        140: 600
    }
};

function formatNumber(value){
    if(value == null) return '';
    return value.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

class Calculator extends React.Component {
    constructor(props) {
        super(props);


        let {t} = props;

        this.state = {
            contact: {
                mail: '',
            },
            years: [
                {value: '1970', range: '<1970'},
                {value: '1980', range: '1970 - 1990'},
                {value: '1990', range: '1990 - 2000'},
                {value: '2000', range: '2000 - 2010'},
                {value: '2010', range: '>2010'}
            ],
            yearSelected: '1970',
            squareMeters: [
                {value: '1500', range: '1500 - 3000'},
                {value: '3000', range: '3000 - 4500'},
                {value: '4500', range: '4500 - 6000'},
                {value: '6000', range: '> 6000'},
            ],
            squareMeterSelected: '1500',
            typeOfBuilding: [
                {
                    value: 'administrative-building',
                    label: 'Büro', //Administrative buildings
                    icon: administrativeIcon,
                    selectedIcon: administrativeIconSelected
                },
                {
                    value: 'school',
                    label: 'Schule', //School
                    icon: schoolIcon,
                    selectedIcon: schoolIconSelected
                },
                {
                    value: 'industries',
                    label: 'Gesundheitspflege', //Health care
                    icon: industriesIcon,
                    selectedIcon: industriesIconSelected
                },
                {
                    value: 'hotel',
                    label: 'Hotel', //Hotel
                    icon: hotelsIcon,
                    selectedIcon: hotelsIconSelected
                },
            ],
            typeOfBuildingSelected: 'administrative-building',
            priceCalculation: {
                showTable: false,
                total: 0,
                totalSaving: 0,
                totalEmissions: 0,
                totalCost: 0
            },
            buildingSelections: [
                {
                    name: 'Systembewertung und Pilot',
                    value: 'small',
                },
                {
                    name: 'Erster Schritt zur Reduzierung des CO2 -Fussabdrucks Ihrer Stadt',
                    value: 'medium',
                },
                {
                    name: 'Ergreifen Sie konkrete Massnahmen zum Schutz der Umwelt',
                    value: 'large',
                },
            ],
            buildingSelection: 'small',
            buyingModels: [
                {
                    name: '',
                    value: ''
                },
                {
                    name: 'Miete',
                    value: 'rent',
                },
                {
                    name: 'Kaufen',
                    value: 'buy',
                }
            ],
            buyingModel: 'buy',
            energySources: [
                {
                    name: 'Gas',
                    value: 'gas'
                },
                {
                    name: 'Wärmepumpe',
                    value: 'heat-pump' //0.9
                },
                {
                    name: 'Heizöl',
                    value: 'heating-oil'
                },
                {
                    name: 'Fernwärme',
                    value: 'district-heating'
                },
                {
                    name: 'Holz',
                    value: 'wood'
                }
            ],
            energySource: 'gas',
            goalSelections: [
                {
                    name: 'Reduzierung der CO2-Emissionen',
                    value: 'co2',
                },
                {
                    name: 'Luftqualität in Gebäuden',
                    value: 'air',
                },
                {
                    name: 'Luftqualität + Reduzierung der CO2-Emissionen',
                    value: 'co2Air',
                }
            ],
            goalSelection: 'co2Air',
            rennovation: false,
        }
        this.onClickSystemRadio = this.onClickSystemRadio.bind(this);
        this.onClickGoalRadio = this.onClickGoalRadio.bind(this);
        this.changeSquareMeters = this.changeSquareMeters.bind(this);
        this.changeYears = this.changeYears.bind(this);
        this.calculate = this.calculate.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    componentDidMount() {
        AOS.init({
            duration: 2000,
            delay: 1000
        });

        this.sendEmail = this.sendEmail.bind(this);
    }

    async sendEmail(e){
        e.preventDefault();
        let t = this.props.t;
        try {
            if (!this.state.contact.mail) throw Error(t('Email is empty.'));
            if(!isValidEmail(this.state.contact.mail)) throw Error('Email is not valid');

            let token = await grecaptcha.execute('6LcHZqUZAAAAAAB9FgyLwilIjZAk8YRT23Uo0OH4', {action: 'submit'});

            await Parse.Cloud.run('send-email', _.merge(
                {
                    subject: 'home-contact-form'
                },
                this.state.contact,
                {token, priceCalculation: this.state.priceCalculation
            }));

            swal(t( 'Vielen Dank! Eine Kontaktanfrage wurde gesendet, ein Berater wird sich in Kürze per E-Mail bei Ihnen melden.'), t(''), 'success');
        } catch (err) {
            console.error(err.message);
            swal('Error', err.message, 'error');
        }
    }

    onClickSystemRadio(buildingSelection){
        this.setState({buildingSelection}, () => {
            if(this.state.priceCalculation.showTable === true) this.calculate();
        });
    }

    onClickGoalRadio(goalSelection){
        this.setState({goalSelection}, () => {
            if(this.state.priceCalculation.showTable === true) this.calculate();
        });
    }

    changeYears(e){
        let yearSelected = e.target.value;

        this.setState({yearSelected}, () => {
            if(this.state.priceCalculation.showTable === true) this.calculate();
        });
    }

    changeSquareMeters(e){
        let squareMeterSelected = e.target.value;

        this.setState({squareMeterSelected}, () => {
            if(this.state.priceCalculation.showTable === true) this.calculate();
        });
    }

    calculate(){
        let getKgCo2FromKWh = (kWh, energySource)  => {
            let kgCo2PerKWh = {
                'gas': 0.202,
                'heat-pump': 0.055,
                'heating-oil': 0.265,
                'district-heating': 0,
                'wood': 0,
                'electricity': 0.18
            }

            return kgCo2PerKWh[energySource] * kWh;
        };
        let getYearlyBuildingConsumptionKWhFrom = (m2, constructionYear) => {
            let kwH = null;
            for(let year of Object.keys(yearConsumptionMap)){
                if(constructionYear <= parseInt(year)){
                    kwH = yearConsumptionMap[year];
                    break;
                }
            }

            return kwH * m2;
        };
        let getYearlyConsumptionCostChf = (consumptionKWh, energySource) => {

            return consumptionPerKWh[energySource] * consumptionKWh;
        };
        let kgToTons = kg => kg / 1000;
        let kgToMilesCar = kg => {
            let tons = kgToTons(kg);

            return tons / Number('3.98E-4');
        }
        let kgToBarrelsOfOil = kg => {
            let tons = kgToTons(kg);

            return tons / 0.43;
        }
        let kgToHomeEnergyPerYear = kg => {
            let tons = kgToTons(kg);

            return tons / 8.3;
        }
        let kgToIncandescentBulbToLED = kg => {
            let tons = kgToTons(kg);

            return tons / Number('2.64E-2');
        }
        let kgToSmartphonesCharged = kg => {
            let tons = kgToTons(kg);

            return tons / Number('8.22E-6');
        }
        let kgToGallonsGasoline = kg => {
            let tons = kgToTons(kg);

            return tons / Number('8.887E-3');
        }
        let kgToGarbageBagsWithRubbish = kg => {
            let tons = kgToTons(kg);

            return tons / Number('2.35E-2');
        }
        let kgToTreeSaplingsIn10Years = kg => {
            let tons = kgToTons(kg);

            return tons / 0.06;
        }
        let milesToKm = miles => miles * 1.60934;
        let gallonsToLiter = gallons => gallons * 3.78541;
        let sqareMeterToThermo = m2 => Math.round(m2 / 30);
        let squareMeterToSense = m2 => Math.round(m2 / 150);
        let getPricePerThermo = numberOfThermo => {
            if(numberOfThermo <= 50){
                return {
                    rent: 60,
                    buy: 160
                }
            } else if (numberOfThermo <= 100){
                return {
                    rent: 55,
                    buy: 150
                }
            } else if (numberOfThermo > 100){
                return {
                    rent: 50,
                    buy: 140
                }
            }
        };
        let getPricePerSense = numberOfThermo => {
            if(numberOfThermo <= 50){
                return {
                    rent: 78,
                    buy: 225
                }
            } else if (numberOfThermo <= 100){
                return {
                    rent: 72,
                    buy: 200
                }
            } else if (numberOfThermo > 100){
                return {
                    rent: 66,
                    buy: 180
                }
            }
        };
        let getRoiSystem = (totalSystemCost, totalChfSavingPerYear) => {
            return totalSystemCost / totalChfSavingPerYear;
        }
        let sendEmail = () => {
            return request
                .post('/api/send-email?subject=calculate-price-automatic')
                .send(_.merge({}, this.state, {automatic: true})) // sends a JSON post body
                .set('accept', 'json')
                .then(() => {});
        };
        let getYearlySavings = (numberOfThermo, numberOfSense, year, squareMeterPerThermo, squareMeterPerSensor) => {
            let kwHPerThermo = savingsPerThermokWh[year][squareMeterPerThermo];
            let kwHPerSensor = savingsPerSensorkWh[year][squareMeterPerSensor];

            return kwHPerThermo * numberOfThermo + kwHPerSensor * numberOfSense;
        };
        let getCFactor = (rennovation) => {
            if(rennovation === true){
                return 0.5 * 0.8;
            }

            return 1;
        };

        const {t} = this.props;
        let numBuildings = 1;
        let percentageOfSaving = 0.25;
        let typeOfBuilding = this.state.typeOfBuildingSelected;
        let squareMeters = parseInt(this.state.squareMeterSelected);
        let yearSelected = parseInt(this.state.yearSelected);
        let energySource = this.state.energySource;
        let rennovation = this.state.rennovation;
        let buyingModel = this.state.buyingModel;
        let pricePerKWh = consumptionPerKWh[energySource];
        let yearlyConsumptionKWh = getYearlyBuildingConsumptionKWhFrom(squareMeters, yearSelected);
        let yearlyEnergyCostChf = pricePerKWh * yearlyConsumptionKWh;
        let yearlyKgOfCo2 = getKgCo2FromKWh(yearlyConsumptionKWh, energySource);
        let squareMeterPerThermo = numberDevicePerSquareMeter[typeOfBuilding].thermo;
        let squareMeterPerSensor = numberDevicePerSquareMeter[typeOfBuilding].sense;
        let numberOfThermo = squareMeters / squareMeterPerThermo;
        let numberOfSense = squareMeters / squareMeterPerSensor;
        let pricePerThermo = getPricePerThermo(numberOfThermo);
        let pricePerSense = getPricePerSense(numberOfSense);
        let C = getCFactor(rennovation);
        let devicesPrice = numberOfThermo * pricePerThermo[buyingModel]  + numberOfSense * pricePerSense[buyingModel];
        let totalSystemCost = devicesPrice * numBuildings;
        let totalYearlySavingskWh = getYearlySavings(numberOfThermo, numberOfSense, yearSelected, squareMeterPerThermo, squareMeterPerSensor);
        let totalChfSavingPerYear = totalYearlySavingskWh * pricePerKWh * C;
        let roi = getRoiSystem(totalSystemCost, totalChfSavingPerYear);

        console.table({
            numBuildings,
            percentageOfSaving,
            typeOfBuilding,
            squareMeters,
            yearSelected,
            energySource,
            pricePerKWh,
            yearlyConsumptionKWh,
            yearlyEnergyCostChf,
            yearlyKgOfCo2,
            squareMeterPerThermo,
            squareMeterPerSensor,
            numberOfThermo,
            numberOfSense,
            pricePerThermo,
            pricePerSense,
            devicesPrice,
            totalSystemCost,
            totalYearlySavingskWh,
            totalChfSavingPerYear,
            roi
        });

        let equivalency = {
            kmCar:                  formatNumber(milesToKm(kgToMilesCar(yearlyKgOfCo2))),
            barrelsOfOil:           formatNumber(kgToBarrelsOfOil(yearlyKgOfCo2)),
            homeEnergyPerYear:      formatNumber(kgToHomeEnergyPerYear(yearlyKgOfCo2)),
            incandescentBulbToLED:  formatNumber(kgToIncandescentBulbToLED(yearlyKgOfCo2)),
            smartphonesCharged:     formatNumber(kgToSmartphonesCharged(yearlyKgOfCo2)),
            literGasoline:          formatNumber(gallonsToLiter(kgToGallonsGasoline(yearlyKgOfCo2))),
            garbageBagsWithRubbish: formatNumber(kgToGarbageBagsWithRubbish(yearlyKgOfCo2)),
            treeSaplingsIn10Years:  formatNumber(kgToTreeSaplingsIn10Years(yearlyKgOfCo2)),
        }

        this.setState(prev => {
            prev.priceCalculation.showTable = true;
            prev.priceCalculation.total = totalSystemCost;
            prev.priceCalculation.totalSaving = yearlyEnergyCostChf * percentageOfSaving;
            prev.priceCalculation.totalEmissions = yearlyKgOfCo2;
            prev.priceCalculation.totalCost = totalSystemCost;
            prev.priceCalculation.totalSurface = squareMeters * numBuildings;
            prev.priceCalculation.totalBuildings = numBuildings;
            prev.priceCalculation.pricePerBuilding = totalSystemCost / numBuildings;
            prev.priceCalculation.equivalency = equivalency;
            prev.priceCalculation.roi = roi;
            prev.priceCalculation.data = {
                numBuildings,
                percentageOfSaving,
                squareMeters,
                yearSelected,
                energySource,
                yearlyConsumptionKWh,
                yearlyEnergyCostChf,
                yearlyKgOfCo2,
                numberOfThermo,
                numberOfSense,
                pricePerThermo,
                pricePerSense,
                devicesPrice,
                totalSystemCost,
                totalChfSavingPerYear
            }

            return prev;
        }, () => {
            //sendEmail();
        });
    }

    calculatePrice(){
        let kgReductionFromM2 = (m2, constructionYear, percentageOfReduction)  => {
            try {
                let yearConsumptionMap = {
                    1969: 15,
                    1989: 13,
                    1999: 10,
                    2010: 7
                };

                let lPerM2 = 7;
                for(let year of Object.keys(yearConsumptionMap)){
                    if(constructionYear < parseInt(year)){
                        lPerM2 = yearConsumptionMap[year];
                        break;
                    }
                }

                return ((lPerM2 * m2  * 2.65 / 100) * percentageOfReduction);
            } catch (e) {
                console.error(e);
            }
        };
        let literReductionFromM2 = (m2, constructionYear, percentageOfReduction)  => {
            try {
                let yearConsumptionMap = {
                    1969: 15,
                    1989: 13,
                    1999: 10,
                    2010: 7
                };

                let lPerM2 = 7;
                for(let year of Object.keys(yearConsumptionMap)){
                    if(constructionYear < parseInt(year)){
                        lPerM2 = yearConsumptionMap[year];
                        break;
                    }
                }

                return (((lPerM2 * m2 / 100) * percentageOfReduction) * 0.9);
            } catch (e) {
                console.error(e);
            }
        };
        let sendEmail = () => {
            return request
                .post('/api/send-email?subject=calculate-price-automatic')
                .send(_.merge({}, this.state, {automatic: true})) // sends a JSON post body
                .set('accept', 'json')
                .then(() => {});
        };

        const {t} = this.props;
        let numBuildings = this.state.buildingSelection;
        let squareMeters = parseInt(this.state.squareMeterSelected);
        let yearSelected = parseInt(this.state.yearSelected);
        let numDevices = 0;
        let priceDevice = 3;
        let priceCloud = 2;

        if(this.state.topicSelection === 1){
            numDevices = squareMeters / 150;
            priceDevice = 3;
            priceCloud = 2;
        }

        if(this.state.topicSelection === 2){
            numDevices = squareMeters / 30 + squareMeters / 150;
            priceDevice = 3;
            priceCloud = 2;
        }

        if(this.state.topicSelection === 3){
            numDevices = squareMeters / 30 + squareMeters / 150;
            priceDevice = 2;
            priceCloud = 2;
        }

        let devicesPrice = numDevices * priceDevice * 12;
        let cloud = numDevices * priceCloud * 12;
        let totalCostPerYear = (devicesPrice + cloud) * numBuildings;
        let totalKgCo2 = kgReductionFromM2(squareMeters, yearSelected, 30) * numBuildings;
        let totalLiter = literReductionFromM2(squareMeters, yearSelected, 30) * numBuildings * 0.9;

        this.setState(prev => {
            prev.priceCalculation.showTable = true;
            prev.priceCalculation.total = totalCostPerYear;
            prev.priceCalculation.totalSaving = totalLiter;
            prev.priceCalculation.totalEmissions = totalKgCo2;
            prev.priceCalculation.totalCost = totalCostPerYear - totalLiter;
            prev.priceCalculation.totalSurface = squareMeters * numBuildings;
            prev.priceCalculation.totalBuildings = numBuildings;
            prev.priceCalculation.pricePerBuilding = totalCostPerYear / numBuildings;


            return prev;
        }, () => {
            sendEmail();
        });
    }

    render() {
        const {t} = this.props;


        let totalCost = formatNumber(this.state.priceCalculation.total);
        let pricePerTonnes = formatNumber(Math.abs(this.state.priceCalculation.totalCost) / (this.state.priceCalculation.totalEmissions / 1000));
        let totalEmissions = formatNumber(this.state.priceCalculation.totalEmissions);
        let totalSaving = formatNumber(this.state.priceCalculation.totalSaving);
        let totalSurface = formatNumber(this.state.priceCalculation.totalSurface);
        let totalBuildings = formatNumber(this.state.priceCalculation.totalBuildings);
        let pricePerBuilding = formatNumber(this.state.priceCalculation.pricePerBuilding);
        let {
            numBuildings,
            percentageOfSaving,
            squareMeters,
            yearSelected,
            energySource,
            yearlyConsumptionKWh,
            yearlyEnergyCostChf,
            yearlyKgOfCo2,
            numberOfThermo,
            numberOfSense,
            pricePerThermo,
            pricePerSense,
            devicesPrice,
            totalSystemCost,
            totalChfSavingPerYear
        } = this.state.priceCalculation.data?? {};

        return (
            <main className="main">
                <section className="basic-section" style={{marginTop: '20px'}}>
                    <div className="basic-section__row">
                        <div>
                            <div className="container" style={{maxWidth: '920px'}}>
                                <h3>
                                    {
                                        t('Berechnen Sie jetzt das $Einsparpotenzial Ihrer Gebäude')
                                    }
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="calculator-section basic-section">
                    <div className="basic-section__row">
                        <div>
                            <div className="container">
                                <label>{t('Art des Gebäudes')}</label>
                                <div className="building-types">
                                    {
                                        this.state.typeOfBuilding.map(option =>
                                            <div key={option.value}
                                                 className={`building-types-item ${this.state.typeOfBuildingSelected === option.value ? 'active' : ''}`}
                                                 onClick={() => this.setState({typeOfBuildingSelected: option.value}, () => {
                                                     if(this.state.priceCalculation.showTable === true) this.calculate();
                                                 })}>
                                                <img src={this.state.typeOfBuildingSelected === option.value ? option.selectedIcon : option.icon} />
                                                <div className="building-types-item-label">{t(option.label)}</div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="container">
                                <div className="building-fields">
                                    <div className="building-fields-item">
                                        <label>{t('Bauhjahr')}</label>
                                        <div
                                            className={`select-custom buying-model-selection ${this.state.displayBuildingYearDropdown ? 'active' : ''}`}
                                            onClick={() => {
                                                this.setState({displayBuildingYearDropdown: !this.state.displayBuildingYearDropdown})
                                            }}>
                                            <div className="select-custom-status">
                                                {this.state.yearSelected
                                                    ? this.state.years.find((b) => b.value === this.state.yearSelected).range
                                                    : ''}
                                            </div>
                                            <ul className="select-custom-list">
                                                {
                                                    this.state.years.map(type => {
                                                        return <li key={type.value}
                                                                   className="select-custom-item"
                                                                   onClick={() => {
                                                                       this.setState({yearSelected: type.value}, () => {
                                                                           if(this.state.priceCalculation.showTable === true) this.calculate();
                                                                       })
                                                                   }}
                                                        >{type.range}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="building-fields-item">
                                        <label>{t('Fläche')}</label>
                                        <div
                                            className={`select-custom buying-model-selection ${this.state.displaySquareMeterSelectionDropdown ? 'active' : ''}`}
                                            onClick={() => {
                                                this.setState({displaySquareMeterSelectionDropdown: !this.state.displaySquareMeterSelectionDropdown})
                                            }}>
                                            <div className="select-custom-status">
                                                {this.state.squareMeterSelected
                                                    ? this.state.squareMeters.find((b) => b.value === this.state.squareMeterSelected).range
                                                    : ''}
                                            </div>
                                            <ul className="select-custom-list">
                                                {
                                                    this.state.squareMeters.map(type => {
                                                        return <li key={type.name}
                                                                   className="select-custom-item"
                                                                   onClick={() => {
                                                                       this.setState({squareMeterSelected: type.value}, () => {
                                                                           if(this.state.priceCalculation.showTable === true) this.calculate();
                                                                       })
                                                                   }}
                                                        >{type.range}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div> 
                                    
                                    <div className="building-fields-item">
                                        <label>{t('Energieträger')}</label>
                                        <div
                                            className={`select-custom buying-model-selection ${this.state.displayEnergySourceDropdown ? 'active' : ''}`}
                                            onClick={() => {
                                                this.setState({displayEnergySourceDropdown: !this.state.displayEnergySourceDropdown})
                                            }}>
                                            <div className="select-custom-status">
                                                {this.state.energySource
                                                    ? t(this.state.energySources.find((b) => b.value === this.state.energySource).name)
                                                    : ''}
                                            </div>
                                            <ul className="select-custom-list">
                                                {
                                                    this.state.energySources.map(type => {
                                                        return <li key={type.name}
                                                           className="select-custom-item"
                                                           onClick={() => {
                                                               this.setState({energySource: type.value}, () => {
                                                                   if(this.state.priceCalculation.showTable === true) this.calculate();
                                                               })
                                                           }}
                                                        >{t(type.name)}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <label>{t('Energetisch saniert')}</label>
                                <div className="rennovation-wrapper">
                                    <label className="radio-area">
                                        <input type="radio" name="rennovation" value="true" className="radio-area__input"
                                               checked={this.state.rennovation === true}
                                               onClick={() => this.setState({rennovation: true}, () => {
                                                   if(this.state.priceCalculation.showTable === true) this.calculate();
                                               })} />
                                        <div className={`radio-area__radio ${this.state.rennovation === true ? 'active' : ''}`} />
                                        <div className="radio-area__placeholder">
                                            <span>{t('Ja')}</span>
                                        </div>
                                    </label>
                                    <label className="radio-area">
                                        <input type="radio" name="rennovation" value="false" className="radio-area__input"
                                               checked={this.state.rennovation === false}
                                               onClick={() => this.setState({rennovation: false}, () => {
                                                   if(this.state.priceCalculation.showTable === true) this.calculate();
                                               })} />
                                        <div className={`radio-area__radio ${this.state.rennovation === false ? 'active' : ''}`} />
                                        <div className="radio-area__placeholder">
                                            <span>{t('Nein')}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="container">
                                <div className='buying-model-fields'>
                                    {/*}
                                    <div>
                                        <label>{t('component-10-label-17')}</label>
                                        <div
                                            className={`select-custom buying-model-selection ${this.state.displaybuyingModelSelectionDropdown ? 'active' : ''}`}
                                            onClick={() => {
                                                this.setState({displaybuyingModelSelectionDropdown: !this.state.displaybuyingModelSelectionDropdown})
                                            }}>
                                            <div className="select-custom-status">
                                                {this.state.buyingModel
                                                    ? t(this.state.buyingModels.find((b) => b.value === this.state.buyingModel).name)
                                                    : ''}
                                            </div>
                                            <ul className="select-custom-list">
                                                {
                                                    this.state.buyingModels.map(type => {
                                                        return <li key={type.name}
                                                                   className="select-custom-item"
                                                                   onClick={() => {
                                                                       this.setState({buyingModel: type.value}, () => {
                                                                           if(this.state.priceCalculation.showTable === true) this.calculate();
                                                                       })
                                                                   }}
                                                        >{t(type.name)}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div> */}
                                    <div className="calculate-button-wrapper">
                                        <button className={`calculate-button ${this.state.priceCalculation.showTable ? 'calculated' : ''}`}
                                                onClick={this.calculate}>
                                            {t('Berechnen Sie')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {
                    this.state.priceCalculation.showTable && <section className="calculator-result">
                        <div className="calculator-result__result-block">
                            <div>
                                <div className="wrapper">
                                    <div className="calculator-result-container">
                                        <div className="calculator-result-wrapper">
                                            <div className="calculator-result-item-icon">
                                                <img src={cloudIcon} loading={'lazy'} />
                                            </div>
                                            <div className="calculator-result-item">
                                                <div className="h3">{formatNumber(this.state.priceCalculation.totalEmissions)}</div>
                                            </div>
                                            <div className="calculator-result-item">
                                                <div className="text-section__info">
                                                    {t('Kg')} CO<sub>2</sub>
                                                    <br />
                                                    {t('Reduzierte Emissionen pro Jahr')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="calculator-result-wrapper">
                                            <div className="calculator-result-item-icon">
                                                {
                                                    this.state.buyingModel === 'buy' && <img src={chfIcon} loading={'lazy'} alt="" />
                                                }
                                                {
                                                    this.state.buyingModel === 'rent' && <img src={currencyIcon} loading={'lazy'} alt="" />
                                                }
                                            </div>
                                            <div className="calculator-result-item">
                                                <div className="h3">{totalCost}</div>
                                            </div>
                                            <div className="calculator-result-item">
                                                {
                                                    this.state.buyingModel === 'buy' && <div
                                                        className="text-section__info"
                                                    >
                                                        {t('EUR')}<br />
                                                        {t("Geschätzte Kosten pro Gebäude. Alles inbegriffen.")}
                                                    </div>
                                                }
                                                {
                                                    this.state.buyingModel === 'rent' && <div
                                                        className="text-section__info"
                                                    >
                                                        {t('Jahre')}<br />
                                                        {t("Rentabilität der Investition")}
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="calculator-result-wrapper">
                                            <div className="calculator-result-item-icon">
                                                <img src={chartIcon} loading={'lazy'} alt="" />
                                            </div>
                                            <div className="calculator-result-item">
                                                <div className="h3">{this.state.priceCalculation.roi.toFixed(1)}</div>
                                            </div>
                                            <div className="calculator-result-item">
                                                <div className="text-section__info">
                                                    {t('Jahre')}<br />
                                                    {t('Rentabilität der Investition')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <SectionCallModalGreenButton />
                                    <SectionDemoModalGreenButton />
                                </div>
                            </div>
                        </div>
                        <div>

                                {/*
                                <div className="text-section">
                                <div className="wrapper">
                                    <div>
                                        <h5>{t('Wie werden sie berechnet?')}</h5>
                                        <p>Price {t(this.state.energySources.find((b) => b.value === this.state.energySource).name)}: {consumptionPerKWh[energySource]} CHF/kWh</p>
                                        <p>Yearly estimated consumption: {formatNumber(yearlyConsumptionKWh)} kWH</p>
                                        <p>Yearly estimated avg. consumption per m2: {yearlyConsumptionKWh / squareMeters} kWH/m2</p>
                                        <p>Yearly estimated energy savings: {formatNumber(yearlyConsumptionKWh * percentageOfSaving)} kwH</p>
                                        <p>Total estimated yearly savings in CHF: {formatNumber(totalChfSavingPerYear)}.-</p>
                                        <p>
                                            {t('component-10-label-16')}
                                        </p>
                                    </div>
                                </div>
                                </div>
                                */}

                            <div className="cta-section no-margin">
                                <div className="cta-section__row">
                                    <div className="cta-section__box bg-decor">
                                        <div className="cta-section__container cta-section__container--row">
                                            <div className="cta-section__info">
                                                <h5>{t('Kontakt aufnehmen')}</h5>
                                                <p>
                                                    {t('Senden Sie uns Ihre Ergebnisse und nehmen Sie Kontakt für eine weitere Beratung auf')}
                                                </p>
                                            </div>
                                            <form action="" className="cta-section__form">
                                                <div className="cta-section__form-row">
                                                    <label className="cta-section__label" data-label={t('Kontakt')}>
                                                        <input
                                                            type="text"
                                                            className="cta-section__input"
                                                            placeholder={t('Kontakt')}
                                                            onChange={(e) => this.setState({
                                                                ...this.state ,contact: { mail: e.target.value }
                                                            })}
                                                        />
                                                    </label>
                                                    <label className="cta-section__submit-label">
                                                        <input
                                                            type="submit"
                                                            className="cta-section__submit"
                                                            value="Submit"
                                                            onClick={this.sendEmail}
                                                        />
                                                        {t('Senden')}
                                                    </label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                {
                    !this.state.priceCalculation.showTable && <SectionDemo/>
                }
            </main>
        );
    }
}

export default translate(Calculator);

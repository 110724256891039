'use strict'

import React from 'react';

import translate from "../translate";

class PageImpressum extends React.Component{
    constructor(props) {
        super(props);
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;

        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {t('Impressum')}
                            </h2>
                        </div>
                    </div>
                </section>


                <section className='faq'>
                    <div className='wrapper'>
                        <p>
                        Angaben gemäß § 5 TMG <br/><br/><br/>
                            <h4>Cleveron Europe GmbH</h4>
                            Auf der Höhe 15<br/>
                            78576 Emmingen-Liptingen<br/>
                            Deutschland<br/><br/>
                            Handelsregister: HRB 787205<br/>
                            Registergericht: Amtsgericht Stuttgart<br/><br/>

                            <h4>Geschäftsführer</h4>
                            Pietro Gagliardi<br/>
                            Simone Cogno<br/><br/>

                            <h4>Kontakt</h4>
                            info@cleveron.ch<br/>
                            +41 31 539 11 23
                            <br/><br/>

                            <h4>Umsatzsteuer-ID</h4>
                            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br/>
                            DE357281011<br/><br/>

                            <h4>Sitz, Kontakt- und Büroadresse Muttergesellschaft</h4>
                            Cleveron AG<br/>
                            Stauffacherstrasse 65/13b<br/>
                            CH-3014 Bern<br/>
                            Schweiz<br/><br/>

                            <h4>Haftungshinweis</h4>
                            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                        </p>
                    </div>
                </section>
            </>
        );
    }
}

export default translate(PageImpressum);

'use strict';

import React from 'react';

import translate from '../translate';
import paths from '../../lib/paths'
import arrowIcon from '../../assets/icon/arrow-white.svg';


import dietikon from '../../assets/clients/Sans-titre-2.webp';
import chiasso from '../../assets/clients/Chiasso@2x.webp';
import stadtZuerich from '../../assets/clients/Zurich@2x.webp';
import seuzach from '../../assets/clients/Seuzach@2x.webp';
import adliswil from '../../assets/clients/Adliswil2@2x.webp';
import bellinzona from '../../assets/clients/Bellinzona@2x.webp';
import locarno from '../../assets/clients/Locarno@2x.webp';
import minusio from  '../../assets/clients/Minusio@2x.webp';
import kirchberg from  '../../assets/clients/Kirchberg@2x.webp';
import basserdorf from  '../../assets/clients/Basserdorf@2x.webp';
import dachsen from  '../../assets/clients/Dachsen@2x.webp';
import altesTramdepot from  '../../assets/clients/AltesTramdepot_resto@2x.webp';
import bkw from  '../../assets/clients/BKW@2x.webp';
import communFounex from  '../../assets/clients/commun_founex.webp';
import hesSoFribourg from  '../../assets/clients/hes-so-fribourg.webp';
import kantonBern from  '../../assets/clients/Kanton_Bern_RGB.svg';
import vdn from  '../../assets/clients/logo-vdn-header-2021.webp';
import hamburg from '../../assets/clients/Hamburg.png';
import herrenberg from '../../assets/clients/Herrenberg.png';
import apleona from '../../assets/clients/Apleona.png';
import gegenbauer from '../../assets/clients/Gegenbauer.png';
import meiningen from '../../assets/clients/Meiningen.png';
import landKonstanz from '../../assets/clients/landkreis-konstanz.png';
import landDith from '../../assets/clients/Kreis-Dithmarschen.png';

class SectionClients extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;
		let isIE = false || !!document.documentMode;


        let clients = [
            {link: '', img: hamburg},
			{link: '', img: herrenberg},
			{link: '', img: stadtZuerich},
			{link: '', img: bkw},
			{link: '', img: apleona},
			{link: '', img: gegenbauer},
			{link: '', img: meiningen},
			{link: '', img: landKonstanz},
			{link: '', img: landDith},
			{link: '', img: kantonBern},
            {link: '', img: adliswil},
            {link: '', img: bellinzona},
            {link: '', img: locarno},
            {link: '', img: minusio},
            {link: '', img: altesTramdepot},
            {link: '', img: hesSoFribourg},
            {link: '', img: vdn}
        ];

        return (
			<section className='clients'>
                    <div className='wrapper'>
                        <h3 className='text-center'>{t('Hier ist das $System bereits im Einsatz', {delay: false})}</h3>

                        <div className='clients__row'>
                            {
                                clients.map((client, i) => {
                                    return <div key={i} className='clients__item'>
                                        <a><img src={client.img} loading="lazy" alt='' className='clients__logo' /></a>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </section>
           
			
        )
    }
}

export default translate(SectionClients);
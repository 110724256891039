'use strict'

import React from 'react';

import translate from "../translate";

class PagePrivacyPolicy extends React.Component{
    constructor(props) {
        super(props);
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;

        let privacyPolicy = [
            {
                title: `1. Verantwortliche Stelle und Kontakt`,
                text: `
                Verantwortlich für die Datenverarbeitungen, die wir hier beschreiben, ist die Cleveron Europe GmbH. Anfragen zum Datenschutz können per Brief oder E-Mail an uns gerichtet werden: </br></br>
                Cleveron Europe GmbH</br>
                Pietro Gagliardi</br>
                Auf der Höhe 15</br>
                78576 Emmingen-Liptingen</br>
                support@cleveron.ch</br></br>
                
                Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Einschränkung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte direkt an uns über diese Kontaktdaten.`
            },
            {
                title: `2. Erhebung und Verarbeitung von personenbezogenen Daten`,
                text: `
                Wir verarbeiten personenbezogenen Daten insbesondere in den folgenden Kategorien von Verarbeitungen. </br></br>
                Kunden- und Personendaten, die wir zur Leistungserbringung benötigen und erhalten haben;</br>
                Wenn wir kommunizieren;</br>
                Beim Besuch unserer Website;</br>
                Bei der Nutzung unseres Newsletters;</br>
                Bei der Teilnahme an einer Veranstaltung von uns;</br>
                Wenn wir aus gesetzlichen oder regulatorischen Gründen dazu verpflichtet sind;</br>
                Wenn wir unsere Sorgfaltspflichten oder sonstige berechtigte Interessen wahrnehmen.</br></br>
                Detailliertere Informationen finden Sie in den folgenden Beschreibungen der jeweiligen Kategorien von Verarbeitungen.<br/>
               `
            },
            {
                title: `3. Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung`,
                text: `
                Wir verarbeiten personenbezogenen Daten im Einklang mit dem Datenschutzrecht. Wir verarbeiten personenbezogenen Daten mindestens gemäß einer der folgenden Rechtsgrundlagen:</br>
                zur Erfüllung eines Vertrages mit der betroffenen Person sowie zur Durchführung vorvertraglicher Maßnahmen;</br>
                zur Wahrung berechtigter Interessen von uns oder von Dritten, sofern nicht die Rechte sowie Interessen des Betroffenen überwiegen. Berechtigte Interessen sind insbesondere unser Interesse, unser Angebot dauerhaft, nutzerfreundlich, sicher und verlässlich bereitstellen und bewerben zu können, die Sicherheit sowie der Schutz vor unbefugter Nutzung und die Durchsetzung eigener rechtlicher Ansprüche;</br>
                zur Erfüllung einer rechtlichen Verpflichtung, der wir nach dem jeweils anwendbaren Recht unterliegen;</br>
                zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt;</br>
                mit Einwilligung der betroffenen Person.
                
                `
            },
            {
                title: `4. Zwecke der Datenverarbeitung und personenbezogenen Daten
                `,
                text: `Wir können Ihre personenbezogenen Daten zu mehreren Zwecken verarbeiten. Primär verarbeiten wir diese, um unsere Dienstleistungen Ihnen gegenüber erbringen zu können.
                `
            },
            {
                title: `4.1 Datenverarbeitung zur Vertragsabwicklung
                `,
                text: `Wir verarbeiten in erster Linie die personenbezogenen Daten, die wir im Rahmen unserer Geschäftsbeziehungen mit unseren Kunden und sonstigen Vertragsbeziehungen mit Geschäftspartnern von diesen und weiteren daran beteiligten Personen erhalten.
                Bei den personenbezogenen Daten unserer Kunden handelt es sich insbesondere um folgende Informationen:</br></br>
                Kontaktinformationen (z.B. Name, Vorname, Adresse, Telefonnummer, E-Mail);</br>
                Persönliche Informationen (z.B. Geburtsdatum, Stellenbezeichnung etc.);</br>
                Finanzinformationen (z.B. Daten zu Bankverbindungen);</br>
                Angaben, welche Sie im Rahmen der Kommunikation mit uns bereitstellen.</br></br>
                Diese personenbezogenen Daten verarbeiten wir für die beschriebenen Zwecke gestützt auf die folgenden Rechtsgrundlagen:</br>
                Abschluss oder Abwicklung eines Vertrages mit der betroffenen Person bzw. zugunsten der betroffenen Person, inkl. Vertragsanbahnung und allfällige Durchsetzung (z.B. Beratung, Treuhand);</br>
                Erfüllung einer gesetzlichen Verpflichtung (z. B. Informationen offen zu legen);</br>
                Wahrung berechtigter Interessen, (z.B. für administrative Zwecke, unsere Rechte durchzusetzen, uns gegen Ansprüche zu verteidigen oder um mögliche Interessenkonflikte zu prüfen);</br>
                Einwilligung (z.B. um Ihnen Informationen zuzusenden).
                

                `
            },
            {
                title: `4.2 Indirekte Datenverarbeitung aus Leistungserbringung
                `,
                text: `Wenn wir Leistungen für unsere Kunden erbringen, kann es vorkommen, dass wir auch personenbezogene Daten verarbeiten, die wir nicht direkt bei den betroffenen Personen erhoben haben oder personenbezogene Daten von Drittpersonen. Diese personenbezogenen Daten benötigen wir, um Verträge mit unseren Kunden zu erfüllen. Wir erhalten diese personenbezogenen Daten von unseren Kunden oder von Dritten, die von unseren Kunden beauftragt werden.
                Bei den personenbezogenen Daten der Personen, die mit unseren Kunden in einer Beziehung stehen, handelt es sich insbesondere um folgende Informationen:</br></br>
                Kontaktinformationen (z.B. Name, Vorname, Adresse, Telefonnummer, E-Mail);</br>
                Finanzinformationen (z.B. Daten zu Bankverbindungen).</br>
                Diese personenbezogenen Daten verarbeiten wir für die beschriebenen Zwecke gestützt auf die folgenden Rechtsgrundlagen:</br></br>
                Abschluss oder Abwicklung eines Vertrages mit der betroffenen Person bzw. zugunsten der betroffenen Person (z. B. wenn wir unsere vertraglichen Pflichten wahrnehmen);</br>
                Erfüllung einer gesetzlichen Verpflichtung (z. B. wenn wir unsere Pflichten wahrnehmen oder verpflichtet sind, Informationen offen zu legen).</br>
                

                `
            },
            
            {
                title: `4.3 Nutzung unserer Webseite
                `,
                text: `Um unsere Webseite zu nutzen, müssen keine personenbezogenen Daten offengelegt werden. Der Server erfasst jedoch mit jedem Aufruf eine Reihe von Benutzerinformationen, welche vorübergehend in den Logfiles des Servers gespeichert werden. 
                Bei der Nutzung dieser allgemeinen Informationen findet keine Zuordnung zu einer bestimmten Person statt. Die Erfassung dieser Informationen bzw. Daten ist technisch erforderlich, um unsere Webseite anzuzeigen und dessen Stabilität und Sicherheit zu gewährleisten. Diese Informationen werden zudem erhoben, um die Webseite zu verbessern und ihre Nutzung zu analysieren. 
                Dabei handelt es sich insbesondere um folgende Informationen: </br></br>
                Kontaktinformationen (z.B. Name, Vorname, Adresse, Telefonnummer, E-Mail);</br>
                Weitere Informationen, die sie uns via Webseite übermitteln;</br>
                Automatisch an uns oder unsere Dienstleister übermittelte technische Informationen, Informationen zum Nutzerverhalten oder der Einstellungen der Webseite (z.B. IP-Adresse, UDI, Gerätetyp, Browser, Anzahl Klicks auf der Seite, Klick auf Links etc.).</br>
                Diese personenbezogenen Daten verarbeiten wir für die beschriebenen Zwecke gestützt auf die folgenden Rechtsgrundlagen:</br></br>
                Wahrung berechtigter Interessen, (z.B. für administrative Zwecke, um unsere Qualität zu verbessern, Daten zu analysieren oder unsere Dienstleistungen bekannt zu machen);</br>
                Einwilligung.
                

                `
            },
            
            {
                title: `4.4 Registrierung und Kundenkonto
                `,
                text: `Damit wir die Anmeldung zu unserer angebotenen Applikation abschließen und abwickeln können, müssen wir Ihre personenbezogenen Daten verarbeiten. Dies ist der Fall, wenn Sie unsere Applikationen nutzen möchten und sich für ein Kundenkonto registrieren oder weitere Angebote von uns im Online- und Offline-Bereich in Anspruch nehmen. Mit der Registrierung können insbesondere folgende Kategorien von personenbezogenen Daten verarbeitet werden:</br></br>
                Personenstammdaten (z.B. Anrede, Vorname, Name, Geburtsdatum);</br>
                Kontaktinformationen (z.B. E-Mail-Adresse, Telefonnummer);</br>
                Weitere Informationen, die sie uns via Webseite übermitteln;</br>
                Automatisch an uns oder unsere Dienstleister übermittelte technische Informationen, Informationen zum Nutzerverhalten oder der Einstellungen der Webseite (z.B. IP-Adresse, UDI, Gerätetyp, Browser, Anzahl Klicks auf der Seite, Klick auf Links etc.).</br></br>
                Zum Zweck der Vertragserfüllung und der Registrierung können wir sämtliche Verarbeitungen vornehmen, die für den Vertragsabschluss, die Abwicklung oder zur Durchsetzung notwendig sind. Wir können Ihre Daten auch im Zusammenhang mit Anfragen Ihrerseits zum Produkt, zur Behebung von Mängeln, zur Behandlung von Beschwerden oder zur Bewertung verarbeiten.
                `
            },
            
            {
                title: `TITLE`,
                text: `sadasd

                `
            },
            
            {
                title: `TITLE`,
                text: `sadasd

                `
            },
        ];

        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {t('Datenschutzerklärung')}
                            </h2>
                        </div>
                    </div>
                </section>

                <section className='faq'>
                    <div className='wrapper'>
                    Diese Datenschutzerklärung gilt für das Internetangebot der Cleveron Europe GmbH, welches unter der Domain www.cleveron.ch, wwww.cleveron-system.de, wwww.cleveron-system.com sowie den verschiedenen Subdomains erreichbar ist.

                        <ul className='faq__list'>
                            {
                                privacyPolicy.map((section, i) => {
                                    return <li id={`agb-${i}`} className='faq__item'>
                                        <h4 className='faq__quastion'>{section.title}</h4>
                                        <div className='faq__answer' dangerouslySetInnerHTML={{__html: section.text}}>
                                        </div>
                                        <div className='faq__open-btn' onClick={() => this.openDescriptionBlock(`agb-${i}`)}>
                                            <span className='faq__btn-line' />
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </section>
            </>
        );
    }
}

export default translate(PagePrivacyPolicy);

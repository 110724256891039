'use strict'

import React from 'react';

import translate from "../translate";

class PageAgb extends React.Component{
    constructor(props) {
        super(props);
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;



        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {t('Cookie-Erklärung')}
                            </h2>
                        </div>
                        Text:
                        <script id="CookieDeclaration" src="https://consent.cookiebot.com/709d4319-d917-4489-90b7-6e91141d8401/cd.js" type="text/javascript" async></script>
                    </div>
                </section>
            </>
        )
    }
}

export default translate(PageAgb);

'use strict';

import React from 'react';

import translate from "../translate";
import paths from '../../lib/paths'
import arrowIcon from '../../assets/icon/arrow-white.svg';

class SectionDiscoverNextSteps extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <section className="basic-section section-discover-ecosystem-container">
                <div className="section-discover-ecosystem-wrapper">
                    <div className="section-discover-ecosystem">
                        <div className="section-discover-ecosystem-item">
                            <h2 className="section-discover-ecosystem-item-title">
                                {t(`Ihr erster Schritt zur cleveren Heizungssteuerung`)}
                            </h2>
                            <div className="section-discover-ecosystem-item-description">
                                {t(`Dank unserer Expertise im Bereich der intelligenten Heizungssteuerung können wir in enger Zusammenarbeit mit Ihnen eine signifikante Senkung Ihres Energieverbrauchs erreichen.`)}
                            </div>
                            <a href={paths.collaborationPublic} className="section-discover-ecosystem-item-button">
                                {t('Erste Schritte für den öffentlichen Sektor')} <img src={arrowIcon} />
                            </a>
                            <a href={paths.collaborationCompany} className="section-discover-ecosystem-item-button">
                                {t('Erste Schritte für Unternehmen')} <img src={arrowIcon} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(SectionDiscoverNextSteps);
'use strict';

import React from 'react';

import translate from "../translate";
import paths from '../../lib/paths'
import IconFirst from '../../assets/icon/discover-application/phone-grey.svg';
import IconSecond from '../../assets/icon/discover-application/building-grey.svg';
import IconThird from '../../assets/icon/discover-application/chart-grey.svg';
import arrowIcon from '../../assets/icon/arrow-pdf.svg';

class SectionDiscoverPlattform extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <section className="basic-section section-discover-application-container">
                <div className="section-discover-application-wrapper">
                    <div className="section-discover-application">
                        <div className="section-discover-application-item">
                            <h2 className="section-discover-application-item-title">
                                {t(`Entdecken Sie unsere cloudbasierte Plattform`)}
                            </h2>
                            <div className="section-discover-application-item-description">
                                {t(`Entdecken Sie jetzt alle Funktionen unserer cloudbasierten Plattform, um das volle Potenzial zur Senkung Ihrer Energiekosten und des CO2-Ausstoßes zu nutzen.`)}
                            </div>
                            <a href={paths.plattform} className="section-discover-application-item-button">
                                {t('Zur Plattform')} <img src={arrowIcon} />
                            </a>
                        </div>
                        <div className="section-discover-application-icons">
                            <img src={IconFirst} />
                            <img src={IconSecond} />
                            <img src={IconThird} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(SectionDiscoverPlattform);
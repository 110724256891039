'use strict';

import React from 'react';
import AOS from "aos";
import slick from 'slick-carousel';
import $ from 'jquery';
import translate from '../translate';
import moment from "moment";
import Butter from 'buttercms';
import profileImage from '../../assets/icon/testimonials/testimonial-icon.svg';
import nextSlideImage from '../../assets/icon/next-slide.svg';
import arrowImage from '../../assets/icon/arrow.svg';

import {cookiePopup} from '../../lib/ui-utils';

import ContactCTA from "../contactCTA";

const butter = Butter('e68de44558c65c5f13908ff04132856386aaa93b');

class News extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            post: {
                author: {
                    first_name: '',
                    last_name: '',
                    profile_image: null,
                },
                tags: [],
            },
            published: '',
            recentPosts: [],
        }
    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });
        let bigImgSlider = $('.section-slider__slider');

        bigImgSlider.slick({
            speed: 800,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,

        });

        // cookiePopup();
        this.loadPost();
        this.loadRecentPosts();
    }

    loadRecentPosts(){
        let languageMap = {
            it: 'italiano',
            de: 'deutsch'
        };
        butter.tag.retrieve(languageMap[this.props.i18n.language] || 'deutsch', {include: 'recent_posts'})
            .then(response => {
                this.setState({recentPosts: response.data.data.recent_posts})

                let mediaSlider = $('.media__slider');

                mediaSlider.slick({
                    speed: 800,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    prevArrow: $('.arrows__item--prev'),
                    nextArrow: $('.arrows__item--next'),
                    responsive: [
                        {
                            breakpoint: 1370,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 990,
                            settings: {
                                slidesToShow: 3,
                            }
                        },
                        {
                            breakpoint: 770,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                });
            })
            .catch(console.error);
    }


    loadPost(){
        let slug = this.props.match.params.slug;

        butter.post.retrieve(slug)
            .then(resp => {
                document.title = resp.data.data.title;
                this.setState({
                    title: resp.data.data.title,
                    post: resp.data.data,
                    published: moment(resp.data.data.published).format('LL')
                });
            })
            .catch(err => {
                console.error(err);
            });
    }


    render() {
        const {post, published, recentPosts} = this.state;
        const {t} = this.props;

        return (
            <main className="main">
                <section className="sidebar-section">
                    <img src={nextSlideImage} loading={'lazy'}/>
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar sidebar-section__sidebar--bottom">
                                <div className="testimonial-item testimonial-item--left" data-aos="fade-left">
                                    <div className="testimonial-item__img-box author">
                                        <img src={post.author.profile_image?.replace('https://cdn.buttercms.com', 'https://cdn.buttercms.com/resize=width:400') ?? profileImage} alt=""
                                             loading={'lazy'} className="testimonial-item__img" />
                                    </div>
                                    <h4>{t('by')} {post.author.first_name} {post.author.last_name}</h4>
                                    <div className="testimonial-item__date">{published}</div>
                                </div>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <h3 data-aos="fade-left">{post.title}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-slider">
                    <div className="section-slider__slider">
                        <div>
                            <div>
                                <div className="section-slider__item">
                                    <img className="section-slider__img img-object-fit" style={{height: 800}} loading={'lazy'} src={post.featured_image?.replace('https://cdn.buttercms.com', 'https://cdn.buttercms.com/resize=width:1400')} alt="" />
                                </div>
                            </div>
                        </div>
                        {post.featured_image_alt && <div>
                            <div>
                                <div className="section-slider__item">
                                    <img className="section-slider__img" loading={'lazy'} src={post.featured_image_alt} alt="" />
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className="circle-arrows">
                        <div className="circle-arrows__item circle-arrows__item--prev">
                            <img src={nextSlideImage} loading={'lazy'} alt="" className="circle-arrows__arrow" />
                        </div>

                        <div className="circle-arrows__item circle-arrows__item--next">
                            <img src={nextSlideImage} loading={'lazy'} alt="" className="circle-arrows__arrow" />
                        </div>
                    </div>

                </section>
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <div className="social">
                                    <div className="social__box" data-aos="fade-left">
                                        <a
                                            onClick={() => window.open('https://twitter.com/share?url=' + escape(window.location.href) + `&text=${post.title}`, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')}
                                            target="_blank"
                                            className="social__link"
                                        >
                                            <svg className="social__img" id="twitter" xmlns="http://www.w3.org/2000/svg"
                                                 width="24.732"
                                                 height="20.09"
                                                 viewBox="0 0 24.732 20.09">
                                                <path className="fill" id="Shape-2" data-name="Shape"
                                                      d="M24.732,2.377a10.132,10.132,0,0,1-2.913.8A5.1,5.1,0,0,0,24.049.371,10.228,10.228,0,0,1,20.825,1.6,5.078,5.078,0,0,0,12.18,6.228,14.4,14.4,0,0,1,1.723.927,5.079,5.079,0,0,0,3.293,7.7,5.074,5.074,0,0,1,1,7.063v.063A5.079,5.079,0,0,0,5.064,12.1a5.139,5.139,0,0,1-1.337.178,4.87,4.87,0,0,1-.955-.094,5.076,5.076,0,0,0,4.738,3.524,10.179,10.179,0,0,1-6.3,2.168A10.783,10.783,0,0,1,0,17.807,14.341,14.341,0,0,0,7.777,20.09,14.335,14.335,0,0,0,22.212,5.655L22.195,5A10.132,10.132,0,0,0,24.732,2.377Z"/>
                                            </svg>
                                        </a>
                                        <a
                                            onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u='+escape(window.location.href)+ `&t=${post.title}`, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')}
                                            target="_blank"
                                            className="social__link"
                                        >
                                            <svg className="social__img" id="facebook"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="12.323"
                                                 height="23.277"
                                                 viewBox="0 0 12.323 23.277">
                                                <path className="fill" id="Facebook-2" data-name="Facebook"
                                                      d="M8,23.277V12.66h3.632l.545-4.139H8V5.879c0-1.2.338-2.014,2.091-2.014h2.233V.162A30.858,30.858,0,0,0,9.069,0C5.848,0,3.643,1.928,3.643,5.469V8.521H0V12.66H3.643V23.277Z"/>
                                            </svg>
                                        </a>
                                        <a
                                            onClick={() => window.open('https://plus.google.com/share?url='+escape(window.location.href), '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600')}
                                            target="_blank"
                                            className="social__link"
                                        >
                                            <svg className="social__img" id="google" xmlns="http://www.w3.org/2000/svg"
                                                 width="20.368" height="20.368" viewBox="0 0 20.368 20.368">
                                                <path className="fill" id="Shape"
                                                      d="M10.184,8.147v4.074h5.762a6.111,6.111,0,1,1-5.762-8.147,6.02,6.02,0,0,1,3.957,1.475l2.676-3.071A10.092,10.092,0,0,0,10.184,0,10.184,10.184,0,1,0,20.368,10.184V8.147Z"/>
                                            </svg>
                                        </a>
                                        <a href="https://www.instagram.com/cleveronag/" target="_blank" className="social__link">
                                            <svg className="social__img" id="instagram"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="26.187"
                                                 height="26.187"
                                                 viewBox="0 0 26.187 26.187">
                                                <path className="fill" id="Mask-2" data-name="Mask"
                                                      d="M18.961,26.187H7.226A7.235,7.235,0,0,1,0,18.961V7.226A7.235,7.235,0,0,1,7.226,0H18.961a7.235,7.235,0,0,1,7.226,7.226V18.961A7.235,7.235,0,0,1,18.961,26.187ZM7.226,2.323a4.909,4.909,0,0,0-4.9,4.9V18.961a4.909,4.909,0,0,0,4.9,4.9H18.961a4.909,4.909,0,0,0,4.9-4.9V7.226a4.909,4.909,0,0,0-4.9-4.9Zm5.867,17.629a6.858,6.858,0,1,1,6.858-6.858A6.866,6.866,0,0,1,13.094,19.952Zm0-11.356a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,13.094,8.6Z"
                                                      fill="#212123"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <div className="tags" data-aos="fade-right">
                                    <h5>Tags:</h5>
                                    {post.tags.map(tag => (
                                        <a key={tag.slug} className="h5" onClick={() => this.props.history.push(`/media/${tag.slug}`)}>{tag.name}</a>
                                    ))}
                                </div>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <section dangerouslySetInnerHTML={{__html: post.body}} className="news-section" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sidebar-section">
                    { recentPosts.length > 0 &&  <div className="wrapper">
                        <>
                            <div className="sidebar-section__row">
                                <div className="sidebar-section__sidebar">
                                    <h3>News</h3>
                                    <div className="arrows">
                                        <div className="arrows__item arrows__item--prev">
                                            <img className="arrows__arrow" src={arrowImage} loading={'lazy'} alt="" />
                                        </div>
                                        <div className="arrows__item arrows__item--next">
                                            <img className="arrows__arrow" src={arrowImage} loading={'lazy'} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar-section__content media">
                                    <div className="media__slider">
                                        {
                                            recentPosts.map(post => (
                                                <div key={post.slug}>
                                                    <div>
                                                        <div className="media__slider-item">
                                                            <a href={`/news/${post.slug}`}  className="media__img-link">
                                                                <img loading={'lazy'} src={post.featured_image?.replace('https://cdn.buttercms.com', 'https://cdn.buttercms.com/resize=width:400')} alt="" className="media__img" />
                                                            </a>
                                                            <div className="media__title-box">
                                                                <div className="media__tag">
                                                                    { moment(post.published).format('LL')}
                                                                </div>
                                                                <a href={`/news/${post.slug}`} className="h4 media__link">
                                                                    {post.title}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    </div> }
                </section>
                <ContactCTA />
            </main>
        );
    }
}

export default translate(News);

'use strict';

import React from 'react';

import translate from '../translate';
import paths from '../../lib/paths'
import arrowIcon from '../../assets/icon/arrow-white.svg';

class SectionPricingInformation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <section className="basic-section section-pricing-information-container">
                <div className="section-pricing-information-wrapper">
                    <div className="section-pricing-information">
                        <h2 className="section-pricing-information-item-title">
                            {t(`Erfahren Sie mehr`)}
                        </h2>
                        <a href={paths.links.pricingList} className="section-pricing-information-button">
                            {t('Ihre Vorteile')} <img src={arrowIcon} />
                        </a>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(SectionPricingInformation);
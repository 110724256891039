'use strict';

import React from 'react';
import AOS from 'aos';

import translate from "../translate";
import paths from '../../lib/paths'

class Testimonials extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        const mapLoader = new google.maps.plugins.loader.Loader({
            apiKey: 'AIzaSyC2Hw6Y8OoSnHbcur5CGtVFZtVavZqlz0w',
            version: "weekly",
        })

        mapLoader.load().then(() => {
            const mapElement = document.getElementById('map-section');
            const options = {
                zoom: 5,
                center: {lat: 53.41058, lng: -2.97794}
            };

            const googleMap = new google.maps.Map(mapElement, options);
            const marker = new google.maps.Marker({
                position: {lat: 53.41058, lng: -2.97794},
                map: googleMap
            });
            const infoWindow = new google.maps.InfoWindow({
                content: '<h4>We here!</h4>'
            });
            marker.addListener('click', function () {
                infoWindow.open(googleMap, marker);
            });
        })
    }

    render() {
        const {t} = this.props;
        return (
            <main className="main">
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h3>{t('Testimonials')}</h3>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col sidebar-section__col--6">
                                        <div className="text-section__info">
                                            {t(`Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod. Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod`)}
                                        </div>
                                    </div>
                                    <div className="sidebar-section__col sidebar-section__col--6">
                                        <div className="text-section__info">
                                            {t(`Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy
                                                nibh
                                                euismod. Lorem ipsum dolor sit amet,
                                                cons ectetuer adipiscing elit, sed diam nonummy nibh euismod`)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="map-section" id="map-section" data-aos="zoom-out">

                </section>
                <section className="sidebar-section bg-gray">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h3 data-aos="fade-left" data-aos-offset='-10' data-aos-delay="50">
                                    CLEVERON
                                    {t('in numbers')}
                                </h3>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col sidebar-section__col--6">
                                        <div className="testimonial-item" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="150">
                                            <div className="container">
                                                <div className="testimonial-item__img-box">
                                                    <img src="/assets/icon/testimonials/testimonial-icon.svg" alt=""
                                                         loading={'lazy'} className="testimonial-item__img" />
                                                </div>
                                                <h4>Equipped buildings</h4>
                                                <div className="testimonial-item__info">{t(`Since wireframe renderings are
                                                    relatively
                                                    simple and`)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidebar-section__col sidebar-section__col--6">
                                        <div className="testimonial-item" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="200">
                                            <div className="container">
                                                <div className="testimonial-item__img-box">
                                                    <img src="/assets/icon/testimonials/testimonial-icon.svg" alt=""
                                                         loading={'lazy'} className="testimonial-item__img" />
                                                </div>
                                                <h4>M2 OPTIMIZED</h4>
                                                <div className="testimonial-item__info">{t(`Since wireframe renderings are
                                                    relatively
                                                    simple
                                                    and`)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidebar-section__col sidebar-section__col--6">
                                        <div className="testimonial-item" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="250">
                                            <div className="container">
                                                <div className="testimonial-item__img-box">
                                                    <img src="/assets/icon/testimonials/testimonial-icon.svg" alt=""
                                                         loading={'lazy'} className="testimonial-item__img" />
                                                </div>
                                                <h4>{t('INSTALLED DEVICES')}</h4>
                                                <div className="testimonial-item__info">{t(`Since wireframe renderings are
                                                    relatively
                                                    simple
                                                    and`)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidebar-section__col sidebar-section__col--6">
                                        <div className="testimonial-item" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="250">
                                            <div className="container">
                                                <div className="testimonial-item__img-box">
                                                    <img src="/assets/icon/testimonials/testimonial-icon.svg" alt=""
                                                         loading={'lazy'} className="testimonial-item__img" />
                                                </div>
                                                <h4>{t('KG OF CO2 PER YEAR')}</h4>
                                                <div className="testimonial-item__info">{t(`Since wireframe renderings are
                                                    relatively
                                                    simple
                                                    and`)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="basic-section">
                    <div className="basic-section__row">
                        <div className="text-block text-block--padding-top" data-aos="fade-left">
                            <div className="container">
                                <h3>
                                    {t('How much')} <span className="decor-color">{t('impact')}</span>
                                    {t('do you want to make')}
                                </h3>
                            </div>
                        </div>
                        <div className="text-block" data-aos="fade-left">
                            <div className="container">
                                <div className="text-block__info">
                                    {t(`Die Reduzierung des Verbrauchs ist wichtig, um Ihre Umweltbelastung zu verringern. Mit CLEVERON können Sie Ihre Emissionen reduzieren und Kosten sparen`)}
                                </div>
                                <a href={paths.demoRequest} className="btn">{t('Berechnen Sie Ihre Einsparung')}</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

export default translate(Testimonials);

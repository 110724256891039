'use strict';

import React from 'react';
import AOS from "aos";
import swal from 'sweetalert';
import Parse from 'parse';
import $ from "jquery";
import _ from 'lodash';

import translate from "../translate";

const isValidEmail = require('is-valid-email');
import {openChat} from '../../lib/ui-utils';
import paths from "../../lib/paths";

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: '',
                email: '',
                subject: '',
                comment: ''
            },
            errors: {
                name: false,
                email: false,
                subject: false,
            },
            faqActiveCategoryId: 'All',
            faqCategories: [],
            faqList: []
        }
        this.contactFormRef = React.createRef();
        this.sendForm = this.sendForm.bind(this);
        this.getContactFAQs = this.getContactFAQs.bind(this);
    }

    async componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        const mapLoader = new google.maps.plugins.loader.Loader({
            apiKey: 'AIzaSyB96M1pFraIHQu5IWLR-yalbwXtIGY0K2g',
            version: "weekly",
        })
        mapLoader.load().then(() => {
            const mapElement = document.getElementById('map-section');
            const options = {
                center: {lat: 47.3774343, lng: 8.4955363},
                zoom: 8,
            };

            const googleMap = new google.maps.Map(mapElement, options);
            const markerSwitzerland = new google.maps.Marker({
                position: {lat: 46.9670528, lng: 7.4525191},
                map: googleMap
            });
            const markerGermany = new google.maps.Marker({
                position: {lat: 47.9382082, lng: 8.9162491},
                map: googleMap
            });
            const infoWindowSwitzerland = new google.maps.InfoWindow({
                content: '<h5>Cleveron AG</h5><p>Stauffacherstrasse 65/13b<br/>3014 Bern,<br/>Schweiz</p> <a href="https://maps.app.goo.gl/zzsAoX3i9JuJMo2m8" target="_blank">Show in Google Maps</a>'
            });
            const infoWindowGermany = new google.maps.InfoWindow({
                content: '<h5>Cleveron Europe GmbH</h5><p>Auf der Höhe 15<br/>78576 Emmingen-Liptingen,<br/>Deutschland</p> <a href="https://maps.app.goo.gl/fKfphnKqM8uEty8W9" target="_blank">Show in Google Maps</a>'
            });

            infoWindowSwitzerland.open(googleMap, markerSwitzerland);
            infoWindowGermany.open(googleMap, markerGermany);

            markerSwitzerland.addListener('click', function () {
                infoWindowSwitzerland.open(googleMap, markerSwitzerland);
            });

            markerGermany.addListener('click', function () {
                infoWindowGermany.open(googleMap, markerGermany);
            });
        });

        document.getElementById('submit-form').onSubmit = function() {
            return this.sendForm();
        };

        await this.getContactFAQs();
    }

    async getContactFAQs(){
        let response = await Parse.Cloud.run('creator-business-website-report-contact-faq', Object.fromEntries(new URLSearchParams(window.location.search)));
        let faqList = response?.contactFAQs?.filter(faqItem => faqItem.public === 'Public') ?? [];
        let faqCategories = _.uniq(
            faqList
                .map(faqItem => faqItem.category)
                .filter(category => !!category)
        );
        faqCategories.unshift('All');
        this.setState({faqList, faqCategories});
    }

    inputValues(event, field) {
        let value = event.target.value;

        this.setState(prev => {
            prev.form[field] = value;

            prev.errors[field] = false;

            return prev;
        });
    }

    async sendForm(e) {
        const {form: {email, comment, name, subject}} = this.state;
        const {t} = this.props;

        this.setState({
            form:{...this.state.form},
            errors: {
                name: !name,
                email: !email,
                isValidEmail: !isValidEmail(email)
            }
        });

        if(!(name && email && isValidEmail(email))) {
            e.preventDefault();
            return
        }

        try {
            let token = await grecaptcha.execute('6LcHZqUZAAAAAAB9FgyLwilIjZAk8YRT23Uo0OH4', {action: 'submit'});

            /*await request
                 .post('/api/send-email?subject=home-contact-form')
                 .send(_.merge({}, {mail: email, name, comment}, {token})) // sends a JSON post body
                 .set('accept', 'json');*/

            await Parse.Cloud.run('send-email', {
                mail: email,
                name,
                subject,
                comment,
                token
            });

            this.setState({
                form: {
                    name: '',
                    email: '',
                    subject: '',
                    comment: ''
                },
                errors: {
                    name: false,
                    email: false,
                    subject: false,
                }
            });

            swal(t( 'Thank you!'), t('A contact request has been sent, a specialist will be in touch via email soon.'), 'success');
        } catch (e) {
            console.error(e.message);
            swal('Error', e.message, 'error');
        }
    }


    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {errors, form: {name, email, subject, comment}} = this.state;
        const {t} = this.props;

        let language = this.props.i18n.resolvedLanguage;

        let instagramPosts = [
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/1_e_ToxYHKV.jpeg?updatedAt=1634908033569',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            },
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/2_J8Hv5U6j_.jpeg?updatedAt=1634908033458',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            },
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/3_umUkqFWEM.jpeg?updatedAt=1634908033183',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            },
            {
                imageUrl: 'https://ik.imagekit.io/sh/cleveron_ch/4_RfkfotkCR.jpeg?updatedAt=1634908024322',
                linkInstagram: 'https://www.instagram.com/cleveronag/'
            }
        ];

        return (
            <main className="main">
                <section className="basic-section contact-section">
                    <div className="basic-section__row">
                        <div className="text-block" style={{paddingBottom: 0}}>
                            <div className="container">
                                <div>
                                    <h3 style={{borderBottom: "1px solid #040405", paddingBottom: "10px"}}>
                                        {t('Kontakt')}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> {/*
                <section className="basic-section">
                    <div className='contact-section-problem-type'>
                        <div className='contact-section-problem-type-item'>
                            <a id='having-issue-with-device'  onClick={(e) => {
                                window.location.href = 'mailto:support@cleveron.ch';
                                e.preventDefault();
                            }}>{t('page-5-label-15')}</a>
                        </div>
                        <div className='contact-section-problem-type-item'>
                            <a onClick={(e) => {
                                window.location.href = 'mailto:info@cleveron.ch';
                                e.preventDefault();
                            }}>{t('page-5-label-16')}</a>
                        </div>
                    </div>
                        </section>
                <section className='contact-section-faq' ref={this.contactFormRef}>
                    <div className='wrapper'>
                        <h3 className='contact-section-faq-title'>{t('page-5-label-29')}</h3>
                        <div className='contact-section-faq-category'>
                            {this.state.faqCategories.map((category) =>
                                <div key={category} className={`contact-section-faq-category-item ${this.state.faqActiveCategoryId === category ? 'active' : ''}`}
                                     onClick={() => this.setState({faqActiveCategoryId: category})}>
                                    {t(`component-13-label-${category}`)}
                            </div>)}
                        </div>
                        <ul className='faq__list'>
                            {this.state.faqList.filter(faq => faq.category === this.state.faqActiveCategoryId || this.state.faqActiveCategoryId === 'All')
                                .map(faq => {
                                    let title = faq[`title_${language}`];
                                    let descriptionHtml = faq[`description_html_${language}`];
                                    let description = faq[`description_${language}`];

                                    return <li id={faq.ID}  key={faq.ID} className='faq__item'>
                                        <h3 className='faq__quastion'>{title}</h3>
                                        {
                                            descriptionHtml != '' &&
                                            descriptionHtml != null &&
                                            <div className='faq__answer' dangerouslySetInnerHTML={{__html:descriptionHtml}}></div>
                                        }
                                        {
                                            (descriptionHtml === '' || descriptionHtml == null)  &&
                                            <div className='faq__answer'>{description}</div>
                                        }
                                        <div className='faq__open-btn' onClick={() => this.openDescriptionBlock(faq.ID)}>
                                            <span className='faq__btn-line' />
                                        </div>
                                    </li>
                                })}
                        </ul>
                    </div>
                </section>*/}
                <section className="basic-section contact-section">
                    <div className="basic-section__row">
                        <div className="text-block">
                            <div className="container">
                                <div className="basic-section__row">
                                   {/*<div>
                                        <div className="text-block__info">
                                            <h5 className="contact-section__title">{t('page-5-label-1')}</h5>
                                            <a href="mailto:info@cleveron.ch">info@cleveron.ch</a>
                                        </div>
                                        <div className="text-block__info">
                                            <h5 className="contact-section__title">{t('page-5-label-2')}</h5>
                                            <a href="tel:+41 (0)31 539 11 23">+41 (0)31 539 11 23</a>
                                        </div>
                                    </div>*/}
                                    <div>
                                        <div className="text-block__info">
                                            <h5 className="contact-section__title">{t('Clevere Heizungssteuerung')}</h5>
                                            {'Cleveron Europe GmbH'}<br/>
                                            {'Auf der Höhe 15'}<br/>
                                            {'78576 Emmingen-Liptingen, Deutschland'}<br/>
                                            <a href="mailto:info@cleveron.ch">info@cleveron.ch</a>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="text-block">
                            <div className="container">
                                <form
                                    id='submit-form'
                                    onSubmit={this.sendForm}
                                    className="contact-form form"
                                    ref={this.contactFormRef}
                                >
                                    <fieldset>
                                        <label className={`form__label ${errors.name ? 'error' : ''}`} data-label="Name" data-error={t('Name ist ungültig')}>
                                            <input
                                                name="name"
                                                id="name"
                                                type="text"
                                                className="form__input"
                                                placeholder={t('Name')}
                                                onChange={(e) => this.inputValues(e, 'name')}
                                                value={name}
                                            />
                                        </label>
                                        <label className={`form__label ${errors.email || errors.isValidEmail ? 'error' : ''}`} data-label={t('E-Mail')}
                                               data-error={t('E-Mail ist ungültig')}>
                                            <input
                                                name="mail"
                                                id="mail"
                                                type="email"
                                                className="form__input"
                                                placeholder={t('E-Mail')}
                                                value={email}
                                                onChange={(e) => this.inputValues(e, 'email')}
                                            />
                                        </label>
                                        <label className="form__label" data-label={t('Telefon')}
                                               data-error="">
                                            <input
                                                type="text"
                                                name="subject"
                                                id="subject"
                                                className="form__input"
                                                placeholder={t('Telefon')}
                                                value={subject}
                                                onChange={(e) => this.inputValues(e, 'subject')}
                                            />
                                        </label>
                                        <label className="form__label" data-label="">
                                            <textarea
                                                name="comment"
                                                id="comment"
                                                cols="30"
                                                rows="10"
                                                value={comment}
                                                placeholder={t('Nachricht')}
                                                onChange={(e) => this.inputValues(e, 'comment')}
                                            />
                                        </label>
                                        <label className="form__submit">
                                            <input type="button" onClick={this.sendForm}/>
                                            <span className="btn">{t('Senden')}</span>
                                        </label>
                                    </fieldset>
                                    
                                    <label>
                                            <a style={{marginBottom: 40}}>Mit dem Absenden dieser Nachricht akzeptieren Sie die Datenschutzbestimmungen der Cleveron Europe GmbH</a>
                                        </label>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="map-section" id="map-section">
                </section>
            </main>
        );
    }
}

export default translate(Contact);
'use strict';

import React from 'react';
import PropTypes from "prop-types";

import translate from '../translate';
import BookDemoModal from '../book-demo-modal';
import BookCallIframeModal from "../book-call-iframe-modal";

class SectionDemoModalGreenButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDemoModal: false
        }
    }

    render() {
        const {t} = this.props;
        let language = this.props.i18n.resolvedLanguage;


        let contactId = '67150000003061016';

        if(language === 'it'){
            contactId = '67150000000341244';
        }

        return (
            <section className="basic-section section-book-demo-container">
                <div className="section-book-demo-wrapper">
                    <div className="section-book-demo">
                        <h2 className="section-book-demo-item-title">
                            {t(`Buchen Sie jetzt ein Beratungsgespräch`)}
                        </h2>
                        <a
                            onClick={() => this.setState({openDemoModal: true})}
                            target={"_blank"}
                            className="section-book-demo-button"
                        >
                            {t('Termin vereinbaren')}
                        </a>
                    </div>
                </div>
                <BookCallIframeModal
                    contactId={contactId}
                    opened={this.state.openDemoModal}
                    onClose={() => this.setState({openDemoModal: false})}
                />
            </section>
        );
    }
}

SectionDemoModalGreenButton.propTypes = {};

export default translate(SectionDemoModalGreenButton);
'use strict';

import React from 'react';
import AOS from 'aos';

import translate from "../translate";
import paths from '../../lib/paths'

class SectionRoomSensors extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }


    render() {
        const {t} = this.props;

        return (
            <section className="basic-section">
                <div className="basic-section__row">
                    <div className="img-block img-block--full">
                        <img className="img img--big"    loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium" loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1400" alt="" />
                        <img className="img img--small"  loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1400"  alt="" />
                    </div>
                    <div className="text-block">
                        <div className="container">
                            <h3>
                                {t('component-1-label-1')}
                            </h3>
                            <div className="text-block__info">
                                {t(`component-1-label-2`)}
                            </div>
                            <a href={paths.air} className="btn">{t('component-1-label-3')}</a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(SectionRoomSensors);

const dbStructure = {
    _User: {
        MONGO: {
            LINKED_HOME: '_p_linkedHome',
            LINKED_DEVICE: '_p_linkedDevice'
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        USERNAME: 'username',
        PASSWORD: 'password',
        EMAIL: 'email',
        EMAIL_VERIFIED: 'emailVerified',
        AUTH_DATA: 'authData',
        PHONE: 'phone',
        UTC_TIME: 'utcTime',
        NAME: 'name',
        LAST_NAME: 'lastName',
        LOCATION: 'location',
        CITY: 'city',
        MQTT_SERVER: 'mqttServer',
        MQTT_USERNAME: 'mqttUsername',
        MQTT_PASSWORD: 'mqttPassword',
        IS_DEVICE: 'isDevice',
        IS_COUPLED: 'isCoupled',
        LINKED_DEVICE: 'linkedDevice',
        LINKED_HOME: 'linkedHome'
    },
    _Role: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        USERS: 'users',
        ROLES: 'roles',
        WHERE: 'where',
        LIMIT: 'limit'
    },
    _Session: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        RESTRICTED: 'restricted',
        USER: 'user',
        INSTALLATION_ID: 'installationId',
        SESSION_TOKEN: 'sessionToken',
        EXPIRES_AT: 'expiresAt',
        CREATED_WITH: 'createdWith'
    },
    Device: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        DEVICE_NAME: 'deviceName',
        OWNER: 'owner',
        ROOM_ID: 'roomId',
        TEMP_MIN: 'tempMin',
        TEMP_DEFAULT: 'tempDefault',
        TOKEN: 'token',
        TOKEN_DOWNLOADED: 'tokenDownloaded',
        MQTT_SERVER: 'mqttServer',
        MQTT_USERNAME: 'mqttUsername',
        MQTT_PASSWORD: 'mqttPassword',
        MAC_ADDRESS: 'macAddress',
        DEVICE_CONFIG: 'deviceConfig',
        STATIC_IP: 'staticIp',
        DEVICE_STATE_FLAG: 'deviceStateFlag'
    },
    Room: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM_NAME: 'roomName',
        TEMP_MIN: 'tempMin',
        TEMP_MAX: 'tempMax',
        AREA: 'area',
        HEIGHT: 'height',
        WINDOWS: 'windows',
        HOME: 'home',
        DATA: 'data',
        PRESENCE_FORECAST_MATRIX: 'presenceForecastMatrix',
        STATE_FLAG: 'stateFlag',
        CORRECTION_TEMP: 'correctionTemp',
        FLOOR: 'floor',
        MAIN_PHOTO: 'mainPhoto'
    },
    Measurement: {
        MONGO: {
            TEMP: 'temp',
            TARGET_TEMP: 'target_temp',
            DEVICE_ID: '_p_deviceId',
            ROOM_ID: '_p_roomId',
            DEVICE_TYP: 'deviceTyp',
            CREATED_AT: '_created_at',
            UPDATED_AT: '_updated_at',
        },
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TEMP: 'temp',
        HUM: 'hum',
        PRESENCE: 'presence',
        MAC_ADDRESS: 'macAddress',
        DEVICE_ID: 'deviceId',
        ROOM_ID: 'roomId',
        MESSAGE: 'message',
        WEATHER_TEMP_C: 'weather_temp_c',
        WEATHER_RELATIVE_HUMIDITY: 'weather_relative_humidity',
        VOLTAGE: 'voltage',
        DEVICE_TYP: 'deviceTyp',
        MOTOR_STATE: 'motorState',
        TARGET_TEMP: 'targetTemp',
        MOTOR_TARGET_STATE: 'motorTargetState',
        EXPECTED_TEMP: 'expected_temp',
        REQUEST: 'request',
        RESPONSE: 'response',
        CO2: 'co2'
    },
    Calendar: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        START_DATE: 'startDate',
        END_DATE: 'endDate',
        STATE: 'state',
        TEMP: 'temp',
        ROOM_ID: 'roomId',
        DEVICE_ID: 'deviceId',
        EVENT_NAME: 'eventName'
    },
    Home: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        YEAR: 'year',
        AREA: 'area',
        VOLUME: 'volume',
        WINDOWS: 'windows',
        PEOPLE: 'people',
        CITY: 'city',
        OWNER: 'Owner',
        SYSTEM: 'system',
        TEMP_C: 'temp_c',
        RELATIVE_HUMIDITY: 'relative_humidity',
        U_VALUE: 'uValue',
        AREA_WALL: 'areaWall',
        TYPE: 'type',
        TIME_ZONE: 'time_zone',
        ALLOW_ADMIN_ACTIONS: 'allowAdminActions',
        DEVICES_CONFIG: 'devicesConfig',
        ALLOW_UPDATE: 'allowUpdate',
        MAIN_PHOTO: 'mainPhoto'
    },
    HomeSystem: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        SYSTEM_NAME: 'systemName',
        PRICE_KWH: 'priceKwh',
        CO_EMISSION: 'coEmission',
        HEAT_COMBUSTION: 'heatCombustion'
    },
    ProgramEvent: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TITLE: 'title',
        START: 'start',
        END: 'end',
        ALL_DAY: 'allDay'
    },
    PresencePreset: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        PRESENCE_PRESET: 'presencePreset'
    },
    RoomPresencePreset: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        ROOM: 'room',
        PRESENCE_PRESET: 'presencePreset',
        APPLIES_AT: 'appliesAt',
        IS_FIXED: 'isFixed',
        APPLIES_AT_DAY: 'appliesAtDay',
        APPLIES_AT_UTC: 'appliesAtUtc'
    },
    Newsletter: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        NAME: 'name',
        EMAIL: 'email'
    },
    TestClass: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        TEST: 'test'
    },
    CommandQueue: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        COMMAND_NAME: 'commandName',
        DATA: 'data',
        DEVICE: 'device'
    },
    DeviceState: {
        OBJECT_ID: 'objectId',
        CREATED_AT: 'createdAt',
        UPDATED_AT: 'updatedAt',
        ACL: 'ACL',
        BATTERY_VOLTAGE: 'batteryVoltage',
        MEMORY_BYTES: 'memoryBytes',
        MODE: 'mode',
        DEBUG: 'debug',
        DEVICE: 'device'
    },
    CampaignUser: {
        NAME: 'name',
        LASTNAME: 'lastName',
        MOTIVATION: 'motivation',
        COMPANY: 'company',
        FUNCTION: 'function'
    },
    ZohoBuilding: {
        ACCOUNT: { NAME: 'Account.name', ID: 'Account.id' },
        ADAPTER_A: 'Adapter_A',
        ADAPTER_B: 'Adapter_B',
        ADAPTER_C: 'Adapter_C',
        ADMINISTRATOR: { NAME: 'Administrator.name', ID: 'Administrator.id' },
        ADMIN_PASSWORD: 'Admin_Password',
        ADMIN_USERNAME: 'Admin_Username',
        APPROVAL: {DELEGATE: 'approval.delegate', APPROVE: 'approval.approve', REJECT: 'approval.reject', RESUBMIT: 'approval.resubmit'},
        APPROVAL_STATE: 'approval_state',
        AUTO_COMMUNICATION: 'Auto_Communication',
        BUILDING_ADDRESS: 'Building_Address',
        BUILDING_AREA: 'Building_area',
        BUILDING_LAYOUTS: 'Building_Layouts',
        BUILDING_RESPONSIBLE: 'Building_Responsible',
        CANTON_REGION: 'Canton_Region',
        CITY: 'City',
        CLOUD_BUILDING_ID: 'Cloud_Building_Id',
        CONTRACT_END: 'Contract_End',
        CONTRACT_LENGTH: 'Contract_Length',
        COUNTRY: 'Country',
        CREATED_BY: {NAME: 'Created_By.name', ID: 'Created_By.id', EMAIL: 'Created_By.email'},
        CREATED_TIME: 'Created_Time',
        CURRENCY: 'Currency',
        CURRENCY_SYMBOL: 'currency_symbol',
        DATE_OF_INSTALLATION: 'Date_of_Installation1',
        EDITABLE: 'editable',
        EMAIL: 'Email',
        EMAIL_OPT_OUT: 'Email_Opt_Out',
        ESTIMATED_DATE_OF_INSTALLATION: 'Estimated_date_of_installation',
        EXIT_POSSIBILITY: 'Exit_possibility',
        E_G_I_D_NUMBER: 'EGID_Number',
        FUTURE_REVENUE: 'Future_revenue',
        HEATING_SYSTEM: 'Heating_System',
        ID: 'id',
        INSTALLATION_ID: 'Installation_Id',
        INSTALLATION_PARTNER: 'Installation_Partner',
        INSTALLATION_REQUIREMENTS_SENT: 'Installation_requirements_sent',
        IN_MERGE: 'in_merge',
        I_T_RESPONSIBLE: 'IT_Responsible',
        LANGUAGE: 'Language',
        LAST_ACTIVITY_TIME: 'Last_Activity_Time',
        LAST_STATUS_UPDATE: 'Last_Status_Update',
        MODIFIED_BY: {NAME: 'Modified_By.name', ID: 'Modified_By.id', EMAIL: 'Modified_By.email'},
        MODIFIED_TIME: 'Modified_Time',
        NAME: 'Name',
        ONE_TIME_REVENUE: 'One_Time_revenue',
        ORCHESTRATION: 'orchestration',
        OWNER: { NAME: 'Owner.name', ID: 'Owner.id', EMAIL: 'Owner.email' },
        PHONE: 'Phone',
        PROCESS_FLOW: 'process_flow',
        PROJECT_STATUS: 'Project_Status',
        QUANTITY_OF_C_O_SENSORS: 'Quantity_of_CO2_Sensors',
        QUANTITY_OF_DISCHARGED_THERMOSTATS: 'Quantity_of_discharged_Thermostats',
        PERCENTAGE_OF_DISCHARGED_THERMOSTATS: 'Percentage_of_Discharged_Thermostats',
        QUANTITY_OF_SENSORS: 'Quantity_of_Sensors',
        QUANTITY_OF_THERMOSTATS: 'Quantity_of_Thermostats',
        RECORD_IMAGE: 'Record_Image',
        REVIEW: 'review',
        REVIEW_PROCESS: {APPROVE: 'review_process.approve', REJECT: 'review_process.reject', RESUBMIT: 'review_process.resubmit'},
        SALES_ORDER: { NAME: 'Sales_Order.name', ID: 'Sales_Order.id' },
        STATE: 'state',
        S_L_A: 'SLA',
        TAG: 'Tag',
        TECHNICAL_RESPONSIBLE: 'Technical_Responsible',
        TECHNICAL_STATUS: 'Technical_Status',
        UNSUBSCRIBED_MODE: 'Unsubscribed_Mode',
        UNSUBSCRIBED_TIME: 'Unsubscribed_Time',
        W_I_F_I_PASSWORD: 'WI_FI_Password2',
        W_I_F_I_SECURITY: 'WI_FI_Security',
        W_I_F_I_S_S_I_D: 'WI_FI_SSID',
        YEARLY_RECURRING_REVENUE: 'Yearly_Recurring_Revenue',
        YEAR_OF_CONSTRUCTION: 'Year_of_construction',
        ZIP_CODE: 'Zip_Code',
        CASE_STUDY_MAIN_IMAGE: 'Case_Study_Main_Image',
        CASE_STUDY_LOGO_IMAGE: 'Case_Study_Logo_Image',
        CASE_STUDY_TESTIMONIAL_NAME: {
            de: 'Case_Study_Testimonial_Name_de'
        },
        CASE_STUDY_CUSTOMER_WEBSITE: 'Case_Study_Customer_Website',
        CASE_STUDY_TESTIMONIAL_POSITION:{
            de: 'Case_Study_Testimonial_Position_de'
        },
        CASE_STUDY_TESTIMONIAL_TEXT: {
            de: 'Case_Study_Testimonial_Text_de'
        },
        CASE_STUDY_TITLE: {
            de: 'Case_Study_Title_de'
        },
        CASE_STUDY_DESCRIPTION: {
            de: 'Case_Study_Description_de'
        },
        CASE_STUDY_EMISSON_SAVED: 'Case_Study_Emissions_Saved',
        CASE_STUDY_ONLINE: 'Case_Study_Online',
        CASE_STUDY_THERMOSTATS: 'Case_Study_Thermostats',
        CASE_STUDY_SENSORS: 'Case_Study_Sensors',
        CASE_STUDY_SURFACE: 'Case_Study_Surface'
    }
};

module.exports = dbStructure;

export const team = [
    {
        name: 'Pietro Gagliardi',
        position: 'Co-founder and CEO',
        //status: 'Co-Founder',
        //education: 'BSc. Engineering',
        image: 'https://ik.imagekit.io/sh/teamphoto2021/IMG_1505_QkwLQElPCG.jpg',
        linkedin: 'https://www.linkedin.com/in/pietro-gagliardi-70141294/',
        email: 'pietro.gagliardi@cleveron.ch'
    },
    {
        name: 'Simone Cogno',
        position: 'Co-founder and CIO',
        image: 'https://ik.imagekit.io/sh/teamphoto2021/IMG_1226_EX6tXxK_6c.jpg',
        linkedin: 'https://www.linkedin.com/in/simone-cogno-a481b782/',
        email: 'simone.cogno@cleveron.ch'
    },
    {
        name: 'Igor Gagliardi',
        position: 'Co-founder and COO',
        //status: 'Co-Founder',
        //education: 'BSc. Engineering',
        image: 'https://ik.imagekit.io/sh/teamphoto2021/IMG_1241_z7VF506cAc.jpg',
        linkedin: 'https://www.linkedin.com/in/igor-gagliardi-40325bb5/',
        email: 'igor.gagliardi@cleveron.ch'
    },
    {
        name: 'Philippe Hamann',
        position: 'Head of Sales',
        image: 'https://ik.imagekit.io/sh/teamphoto2021/IMG_1310_UbuwK7y7D.jpg',
        linkedin: 'https://www.linkedin.com/in/philippe-hamann-6011a6116/',
        email: 'philippe.hamann@cleveron.ch'
    },
    {
        name: 'Marcel Fischer',
        image: 'https://ik.imagekit.io/sh/cleveron_ch/IMG_8906_UfTtvIUzt.JPG',
        email: 'marcel.fischer@cleveron.ch'
    },
    {
        name: 'Lars Grossenbacher',
        position: 'Sales and Marketing Intern',
        image: 'https://ik.imagekit.io/sh/teamphoto2021/IMG_1536_Test_flou_-bcj9Z_Fw.jpg',
        email: 'lars.grossenbacher@cleveron.ch'
    },
    {
        name: 'Swen Bischof',
        position: 'Electrical engineer',
        image: 'https://ik.imagekit.io/sh/teamphoto2021/IMG_1275_suI1ZwelB.jpg',
        linkedin: 'https://www.linkedin.com/in/swen-bischof-b32baba6/',
        email: 'swen.bischof@cleveron.ch'
    },
    {
        name: 'Mieke Duvennhage',
        image: 'https://ik.imagekit.io/sh/cleveron_ch/IMG_8895__2_-min_ukQGx70-H.png',
        email: 'mieke.duvenhage@cleveron.ch'
    },
    {
        name: 'Claudio Capitanio',
        image: '',
        email: 'claudio.capitanio@cleveron.ch'
    },
    {
        name: 'Steven Mathey',
        image: '',
        email: 'steven.mathey@cleveron.ch'
    },
    {
        name: 'Florian Baumann',
        image: '',
        email: 'florian.baumann@cleveron.ch'
    }
]
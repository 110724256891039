'use strict';

import React from 'react';
import AOS from 'aos';
import {capitalize} from 'lodash';

import translate from "../translate";
import paths from '../../lib/paths'
import SectionDiscoverNextSteps from '../section-discover-next-steps';
import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import SectionDemo from "../section-demo";
import SectionRoomSensors from "../section-room-sensors";



class Products extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 2000,
            delay: 1000
        });
    }

    openMobDescription(id) {
        const description = document.getElementById(id);
        if(description.classList.contains('active')) {
            description.classList.remove('active');
        } else {
            description.classList.add('active');
        }
    }

    redirect(path, side) {
        const width = window.innerWidth;

        if(width >= 1365) {
            const element = document.getElementById('product-section');

            element.classList.add('active')
            element.classList.add(side)

            setTimeout(() => this.props.history.push(path), 2500)
        } else {
            this.props.history.push(path)
        }

    }


    render() {
        const {t} = this.props;
        return (
            <>
                <section className='title-section first-screen'>
                    <div className='wrapper'>
                        <div className='title-section__main-info'>
                            <h2>
                                {
                                    t('Ein $modulares Plug & Play-System zur Optimierung der $Heizungssteuerung in Nichtwohngebäuden', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
				<section className='product-section'>
                        <div id='product-section' className='product-section__row'>
							<div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/My%20File%20Copy%20(9)_c9U9TbBZv.png?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/My%20File%20Copy%20(9)_c9U9TbBZv.png?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/My%20File%20Copy%20(9)_c9U9TbBZv.png?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="left" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.props.history.push(paths.heating)}>
                                            {t('Clevere Thermostate')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Unser intelligenter Thermostat ist speziell für den Einsatz in Nichtwohngebäuden konzipiert. Er bietet eine integrierte Präsenzerkennung sowie Sensoren zur Messung von Temperatur, CO2-Gehalt und Feuchtigkeit.`)}
                                        </div>
                                        <a
                                            onClick={() => this.redirect(paths.thermostat, 'left')}
                                            className='btn product-section__link'
                                        >
                                            {t('Erfahren Sie mehr')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="right" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.openMobDescription('right')}>
                                            {t('Cloudbasiert Plattform')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Unsere Webanwendung wurde speziell für den Einsatz in Nichtwohngebäuden entwickelt. Sie erfasst automatisch die Raumnutzung und reguliert die Heizleistung entsprechend.`)}
                                        </div>
                                        <a
                                            onClick={() => this.props.history.push(paths.heating)}
                                            className='btn product-section__link'
                                        >
                                            {t('Erfahren Sie mehr')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </section>
				<div  id="del-fixed" className="wrapper" data-aos-id="del-fixed" data-aos="del-fixed" data-aos-offset="0" style={{marginTop: 60}}>
                    <h3 className="no-margin">
                        {t('Die $Vorteile für Sie und Ihre $Immobilien')}
                    </h3>
                </div>
				<section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row">
							<div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={wind} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Einfach installierbar')}</h4>
                                    <div className="text-section__info">
                                        {t('Das Cleveron System kann innerhalb weniger Tage vollständig installiert werden, ohne dass bauliche Maßnahmen erforderlich sind. Es eignet sich für eine Vielzahl von Gebäuden, darunter Büro- und Verwaltungsgebäude, Bildungseinrichtungen sowie denkmalgeschützte Gebäude.')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={graphic} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Digital gesteuert und vollautomatisiert')}</h4>
                                    <div className="text-section__info">
                                        {t('Von Ihrem gesamten Gebäudeportfolio bis hin zum einzelnen Raum ist alles über unsere benutzerfreundliche Webanwendung abruf- und steuerbar. Das Cleveron System erkennt die Raumnutzung mithilfe fortschrittlicher Präsenzerkennung und passt sich automatisch den Bedürfnissen der Nutzer an.')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={temperature} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t( 'Benutzerzentrierter Ansatz')}</h4>
                                    <div className="text-section__info">
                                        {t('Unsere Webanwendung ist intuitiv gestaltet und ermöglicht Gebäudeverantwortlichen eine einfache Steuerung des Cleveron Systems. Dank integrierter Präsenzerkennung erfasst das System automatisch die Raumnutzung und passt sich dynamisch den Bedürfnissen der Nutzer an.')}
                                    </div>
                                </div>
                            </div>
							<div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={phone} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Modular aufgebaut')}</h4>
                                    <div className="text-section__info">
                                        {t('Das Cleveron System ist modular aufgebaut, sodass Sie die verschiedenen Elemente wie Einzelraumsteuerung mit oder ohne Präsenzerkennung und Luftqualitätssensoren individuell nutzen oder miteinander verbinden können. Darüber hinaus lassen sich die Cleveron Systemkomponenten nahtlos in andere Systeme integrieren und mit einer Vielzahl von Plattformen vernetzen.')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<SectionDemo/>
				<SectionRoomSensors/>
                <SectionDiscoverNextSteps/>
            </>
        )
    }
}

export default translate(Products);

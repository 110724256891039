'use strict'

import React from 'react';
import swal from 'sweetalert';

import translate from '../translate';
import Parse from "parse";

const request = require('superagent');
const isValidEmail = require('is-valid-email');

class ContactCTA extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contact: {
                mail: '',
                isNewsSubscription: false,
                isMakeContact: false
            },
            email: '',
            isMakeContact: false,
            isNewsSubscription: false,
            isContactApplied: false,
            isNewsSubscriptionApplied: false,
        }

        this.sendEmail = this.sendEmail.bind(this);
    }

    async sendEmail(e){
        e.preventDefault();
        let t = this.props.t;
        try {
            if (!this.state.contact.mail) throw Error(t('Email is empty.'));
            if(!isValidEmail(this.state.contact.mail)) throw Error('Email is not valid');

            let token = await grecaptcha.execute('6LcHZqUZAAAAAAB9FgyLwilIjZAk8YRT23Uo0OH4', {action: 'submit'});

            await Parse.Cloud.run('send-email', {
                mail: this.state.contact.mail,
                subject: 'home-contact-form',
                token
            });

            swal(t( 'Thank you!'), t('A contact request has been sent, a specialist will be in touch via email soon.'), 'success');
        } catch (err) {
            console.error(err.message);
            swal('Error', err.message, 'error');
        }
    }


    render() {
        const {t} = this.props;
        const {isMakeContact, isNewsSubscription} = this.state;
        return (
            <section className='cta-section'>
                <div className='cta-section__row'>
                    <div className='cta-section__box bg-decor'>
                        <div className='cta-section__container'>
                            <div className='cta-section__info'>
                                <h3>{t('component-3-label-0')}</h3>
                                <p>{t('component-3-label-1')}</p>
                            </div>
                            <form action='' className='cta-section__form'>
                                <div className="cta-section__form-row">
                                    <label className='cta-section__label' data-label={t('component-3-label-2')}>
                                        <input
                                            type='text'
                                            className='cta-section__input'
                                            placeholder={t('component-3-label-3')}
                                            onChange={(e) => this.setState({
                                                ...this.state ,contact: { mail: e.target.value }
                                            })}
                                        />
                                    </label>
                                    <label className='cta-section__submit-label'>
                                        <input
                                            type='submit'
                                            className='cta-section__submit'
                                            value='Submit'
                                            onClick={this.sendEmail}
                                        />
                                        {t('component-3-label-4')}
                                    </label>
                                </div>
                                <label className="form__checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={isMakeContact}
                                        onChange={() => {}}
                                        onClick={() => this.setState({isMakeContact: !isMakeContact})}
                                    />
                                    <div className="form__checkbox-label-info">
                                        <div className="form__checkbox-square"/>
                                        <div className="form__checkbox-info">
                                            {t('component-3-label-5')}
                                        </div>
                                    </div>
                                </label>
                            </form>
                        </div>
                    </div>
                    <div className='cta-section__box bg-dark'>
                        <div className='cta-section__container'>
                            <div className='cta-section__info'>
                                <h3>{t('component-3-label-6')}</h3>
                                <p>{t('component-3-label-7')}</p>
                            </div>
                            <form
                                action="//tech.us16.list-manage.com/subscribe/post?u=fcb6f6887ae27e7f7e10dab92&id=4242c86ede"
                                method="post"
                                className='cta-section__form'>
                                <div className="cta-section__form-row">
                                    <label className='cta-section__label' data-label={t('component-3-label-8')}>
                                        <input
                                            type='text'
                                            className='cta-section__input'
                                            placeholder={t('component-3-label-9', false)}
                                            name="EMAIL"
                                            id="mce-EMAIL"
                                        />
                                    </label>
                                    <label className='cta-section__submit-label'>
                                        <input
                                            type='submit'
                                            className='cta-section__submit'
                                            value='Submit'
                                        />
                                        {t('component-3-label-10')}
                                    </label>
                                </div>
                                <label className="form__checkbox-label">
                                    <input
                                        type="checkbox"
                                        checked={isNewsSubscription}
                                        onChange={() => {}}
                                        onClick={() => this.setState({isNewsSubscription: !isNewsSubscription})}
                                    />
                                    <div className="form__checkbox-label-info">
                                        <div className="form__checkbox-square" />
                                        <div className="form__checkbox-info">
                                            {t('component-3-label-11')}
                                        </div>
                                    </div>
                                </label>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(ContactCTA);

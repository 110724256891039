'use strict';

import React from 'react';
import AOS from 'aos';
import {capitalize} from 'lodash';

import translate from "../translate";
import paths from '../../lib/paths'
import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import SectionClients from "../section-clients";
import SectionDiscoverNextSteps from "../section-discover-next-steps";



class Collaboration extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 2000,
            delay: 1000
        });
    }

    openMobDescription(id) {
        const description = document.getElementById(id);
        if(description.classList.contains('active')) {
            description.classList.remove('active');
        } else {
            description.classList.add('active');
        }
    }

    redirect(path, side) {
        const width = window.innerWidth;

        if(width >= 1365) {
            const element = document.getElementById('product-section');

            element.classList.add('active')
            element.classList.add(side)

            setTimeout(() => this.props.history.push(path), 2500)
        } else {
            this.props.history.push(path)
        }

    }


    render() {
        const {t} = this.props;
        return (
            <>
                <section className='title-section first-screen'>
                    <div className='wrapper'>
                        <div className='title-section__main-info'>
                            <h2>
                                {
                                    t('Wir sind Ihr $Partner für intelligente $Heizungssteuerung in Ihren $Liegenschaften.', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
				<section className='product-section'>
                        <div id='product-section' className='product-section__row'>
							<div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/csm_Wirtschaftsverkehre_Relation_ad20f9b9de%20(1)_kYuxA69kF.jpg?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/csm_Wirtschaftsverkehre_Relation_ad20f9b9de%20(1)_kYuxA69kF.jpg?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/csm_Wirtschaftsverkehre_Relation_ad20f9b9de%20(1)_kYuxA69kF.jpg?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="left" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.props.history.push(paths.collaborationPublic)}>
                                            {t('Zusammenarbeit mit dem öffentliche Hand')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Einfache Maßnahmen zur Verbesserung der Energieeffizienz in Ihrer Stadt.`)}
                                        </div>
                                        <a
                                            onClick={() => this.redirect(paths.collaborationPublic, 'left')}
                                            className='btn product-section__link'
                                        >
                                            {t('Zum ersten Schritt')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/benjamin-child-0sT9YhNgSEs-unsplash_mmD0GTszX.jpg?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/benjamin-child-0sT9YhNgSEs-unsplash_mmD0GTszX.jpg?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/benjamin-child-0sT9YhNgSEs-unsplash_mmD0GTszX.jpg?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="right" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.openMobDescription(paths.collaborationCompany)}>
                                            {t('Zusammenarbeit mit Unternehmen')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Schnelle Reduzierung der Umweltbelastung Ihrer Immobilien ohne große Investitionen.`)}
                                        </div>
                                        <a
                                            onClick={() => this.props.history.push(paths.collaborationCompany,'right') }
                                            className='btn product-section__link'
                                        >
                                            {t('Zum ersten Schritt')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </section>
				<section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h4>{t('Fit für das Gebäudeenergiegesetz')}</h4>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <div className="text-section__info-box">
                                            <div className="text-section__info">
                                                {t('Das Gebäudeenergiegesetz 2024 (GEG) verpflichtet Eigentümer von Gebäuden mit einem Energieverbrauch von mehr als 290 kW zur Installation von intelligenten Heizsystemen mit Einzelraumregelung*. Cleverons System bietet eine gesetzeskonforme Lösung für Eigentümer von Bestandsgebäuden aller Art. Die Thermostate verfügen über ein Präsenzerfassungssystem, das einen Automatisierungsgrad A ermöglicht. Dadurch verbessern Sie nicht nur Ihren CO2-Ausstoß und Ihr ESG-Rating, sondern sparen direkt Geld bei den Energiekosten ein.')}
                                            </div>
                                        </div>
                                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="300">
                                            <div className="text-section__info">
                                                {t('*Gesetzlichen Anforderungen des GEG 2024 (Gebäudeenergiegesetz) an die Gebäudeautomation, Prof. Dr. Michael Krödel, Institut für Gebäudetechnologie')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<SectionClients/>	
                <SectionDiscoverNextSteps/>			
            </>
        )
    }
}

export default translate(Collaboration);

'use strict';

import React from 'react';
import AOS from 'aos';
import $ from 'jquery';
import translate from "../translate";

import paths from '../../lib/paths';
import ContactCTA from '../contactCTA';
import arrow from '../../assets/icon/arrow.svg';
import SectionDemo from "../section-demo";
import Parse from "parse";
import _ from "lodash";
import SectionDiscoverNextSteps from "../section-discover-next-steps";


class About extends  React.Component{
    constructor(props) {
        super(props);

        this.state = {
            activeItemIndex: 0,
            teamMembers: []
        }
    }

    async componentDidMount() {
        await this.getTeamMembers(() => {
            AOS.init({
                duration: 2000,
                delay: 1000
            });
            const teamSlider = $('.team__slider');
            teamSlider.slick({
                speed: 800,
                rows: 100,
                variableWidth: true,
                infinite: false,
                slidesPerRow: 4,
                swipeToSlide: false,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesPerRow: 3
                        }
                    },
                    {
                        breakpoint: 950,
                        settings: {
                            slidesPerRow: 2
                        }
                    },
                    {
                        breakpoint: 670,
                        settings: {
                            slidesPerRow: 1
                        }
                    }
                ]
            });
        });
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }


    render() {
        const {t} = this.props;
        let language = this.props.i18n.resolvedLanguage;

        return (
            <>
                <section className='title-section first-screen'>
                    <div className='wrapper'>
                        <div className='title-section__main-info'>
                            <h2>
                                {t('Mehrwert für die $Optimierung und die Verwaltung Ihr $Immobilienportfolio', {delay: false})}
                            </h2>
                        </div>
                    </div>
                </section>
				<section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit" style={{height: 600}}    loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit" style={{height: 600}} loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain"                    loading={'lazy'} src="https://ik.imagekit.io/sh/andreas-m--xu5FvpWvSk-unsplash_2uJtn8Vq0rj.jpg?tr=w-700" alt="" />
                    </div>
                </section>
				<div  id="del-fixed" className="wrapper" data-aos-id="del-fixed" data-aos="del-fixed" data-aos-offset="0" style={{marginTop: 60}}>
                    <h3 className="no-margin">
                        {t('Die $Vorteile für Sie und Ihre $Liegenschaften')}
                    </h3>
                </div>
                
                <section className='faq'>
                    <div className='wrapper'>
                        <ul className='faq__list'>
                            <li id='first' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Senkung der Energiekosten')}</h3>
                                <div className='faq__answer'>
                                    {t('Mit einer sofortigen Einsparung von bis zu 30% nach der Installation bietet unser System eine effiziente Lösung. Dank der integrierten Präsenzerkennung erfasst es Nutzungsverhalten in Gebäuden und optimiert die Heizleistung entsprechend. Dies ermöglicht rasche und signifikante Einsparungen, die deutlich über denen herkömmlicher Systeme wie der Nachtabsenkung liegen.')}
                                </div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('first')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                            <li id='second' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Reduktion des CO2 Austsoß')}</h3>
                                <div className='faq__answer'>
                                    {t('Das Cleveron System reduziert den CO2-Ausstoß bereits ab dem ersten Tag. Durch automatische Präsenzerkennung und intelligente Algorithmen optimiert das System die Heizleistung in jedem Raum. Dadurch können sofortige und direkte Einsparungen erzielt werden. Im Durchschnitt reduzieren unsere Kunden ihren CO2-Ausstoß um 20%.')}
                                </div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('second')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                            <li id='third' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Schnelle Amortisation')}</h3>
                                <div className='faq__answer'>
                                    {t('Unser System ist innerhalb weniger Tage installiert und beginnt sofort Einsparungen zu generieren. Es sind keine baulichen Maßnahmen erforderlich, was die Investitionskosten niedrig hält. Dank dieser schnellen Amortisation lohnt sich die Installation finanziell für alle Gebäude. Dadurch ist die Amortisationsdauer kurz und eine Installation lohnt sich für sämtliche Gebäude auch finanziell. ')}
									<br></br>
								</div>
								<div className='faq__open-btn' onClick={() => this.openDescriptionBlock('third')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
							<li id='fourth' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Gesetzeskonform gemäss GEG')}</h3>
                                <div className='faq__answer'>
                                    {t('Das Gebäudeenergiegesetz 2024 (GEG) verpflichtet Eigentümer von Gebäuden mit einem Energieverbrauch von mehr als 290 kW (was ca. 2000m2 entsprich) zur Installation intelligenter Heizsysteme mit Einzelraumregelung. Cleverons System ist die gesetzeskonforme Lösung für alle Eigentümer von Bestandsgebäuden, da unsere Thermostate über ein Präsenzerfassungssystem verfügen, das den Automatisierungsgrad A ermöglicht. Dadurch verbessern Sie nicht nur Ihren CO2-Ausstoß und Ihr ESG-Rating, sondern sparen auch direkt Geld bei den Energiekosten ein.')}
								</div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('fourth')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
							<li id='fifth' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Verbesserung ESG-Rating')}</h3>
                                <div className='faq__answer'>
                                    {t('Der Gebäudesektor ist für rund 40% der CO2-Emissionen verantwortlich. Eine Einsparung in diesem Bereich ermöglicht es Unternehmen und öffentlichen Körperschaften, ihren CO2-Fußabdruck zu reduzieren. Dies hat einen direkten Einfluss auf ihr ESG-Rating, das für Immobilienbesitzer zunehmend relevant wird. Die Reduktion des CO2-Ausstoßes ist jedoch nicht der einzige Faktor, der das ESG-Rating beeinflusst. Auch die Verbesserung der Luftqualität im Inneren eines Gebäudes spielt eine Rolle, da die Arbeitsplatzumgebung Teil des ESG-Reportings ist. Mit dem Cleveron System erreichen Sie beides: Eine Reduktion des CO2-Ausstoßes und eine Verbesserung der Arbeitsplatzumgebung für Ihre Angestellten und Mieter.')}
							</div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('fifth')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
							<li id='sixth' className='faq__item'>
                                <h3 className='faq__quastion'>{t('Gebäudedaten erheben')}</h3>
                                <div className='faq__answer'>
                                    {t('Dank unserer intuitiven und cloudbasierten Plattform können Sie das System zentral steuern und den Gebäudeunterhalt automatisieren. Sie ermöglicht auch die Erfassung und Verwaltung von Daten zur Gebäudenutzung und zum Energieverbrauch. Diese Daten erlauben es Ihnen, Ihre Gebäudekennzahlen auszuwerten und den Gebäudeunterhalt fit für das 21. Jahrhundert zu machen. Offene Schnittstellen ermöglichen den Export und die Integration der Daten in andere Systeme zum Gebäudunterhalt.')}
								</div>
                                <div className='faq__open-btn' onClick={() => this.openDescriptionBlock('sixth')}>
                                    <span className='faq__btn-line' />
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
				<SectionDiscoverNextSteps/>
				<SectionDemo/>
            </>
        )
    }
}

export default translate(About);
'use strict';

import React from 'react';

import translate from "../translate";
import paths from '../../lib/paths'
import IconFirst from '../../assets/icon/discover-application/device-sense.svg';
import IconSecond from '../../assets/icon/discover-application/device-thermo.svg';
import arrowIcon from '../../assets/icon/arrow-pdf.svg';

class SectionDiscoverDevices extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <section className="basic-section section-discover-application-container">
                <div className="section-discover-application-wrapper">
                    <div className="section-discover-application">
                        <div className="section-discover-application-item">
                            <h2 className="section-discover-application-item-title">
                                {t(`Verknüpfen Sie unsere Geräte mit der Plattform`)}
                            </h2>
                            <div className="section-discover-application-item-description">
                                {t(`Erfahren Sie jetzt, welche Funktionen unsere Geräte bieten und wie Sie sie mit unserer cloudbasierten Plattform verbinden können.`)}
                            </div>
                            <a href={paths.thermostat} className="section-discover-application-item-button">
                                {t('Entdecken Sie unsere Thermostate')} <img src={arrowIcon} />
                            </a>
							
							<a href={paths.air} className="section-discover-application-item-button">
                                {t('Entdecken Sie unsere Sensoren')} <img src={arrowIcon} />
                            </a>
                        </div>
                        <div className="section-discover-application-icons">
                            <img src={IconFirst} />
                            <img src={IconSecond} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default translate(SectionDiscoverDevices);
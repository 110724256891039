'use strict'
import React from 'react';
import $ from 'jquery';
import AOS from 'aos';

import paths from '../../lib/paths';
import translate from "../translate";
import SectionHowMuchImpact from "../section-how-much-impact";
import logo from '../../assets/logo.svg';

import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import person from '../../assets/icon/product/person.svg';
import weather from '../../assets/icon/product/weather.svg';
import calendar from '../../assets/icon/product/calendar.svg';
import schema from '../../assets/icon/product/schema.svg';
import result from '../../assets/icon/corporate/result.svg';
import contact from '../../assets/icon/corporate/contact.svg';
import calculation from '../../assets/icon/corporate/calculation.svg';
import pilot from '../../assets/icon/corporate/pilot.svg';
import portfolio from '../../assets/icon/corporate/portfolio.svg';
import optimisation from '../../assets/icon/corporate/optimisation.svg';
import SectionDiscoverApplication from '../section-discover-application';
import SectionPricingInformation from '../section-pricing-information';
import SectionBookDemo from "../section-book-meeting";
import SectionDemo from "../section-demo";
import BookDemoModal from "../book-demo-modal";
import SectionAdvantagesInformation from "../section-advantages-information";
import SectionDiscoverPlattform from "../section-discover-plattform";
import RequestForm from "../request-form";
import SectionClients from "../section-clients";
import SectionProjectSteps from '../section-project-steps';


class CollaborationCompany extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            openDemoModal: false
        }
    }

    componentDidMount() {
        let getParams = Object.fromEntries(new URLSearchParams(window.location.search));

        if(getParams.demoModalOpen === 'true'){
            this.setState({openDemoModal: true});
        }

        window.scroll(0, 0)
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        let offsetAOS = $(window).height();
        $('[data-aos-id="fixed"]').attr('data-aos-offset', offsetAOS);
        $('[data-aos="active"]').attr('data-aos-offset', (offsetAOS / 2));
    }

    render() {
        const {t} = this.props;
		let isIE = false || !!document.documentMode;


        let language = this.props.i18n.resolvedLanguage;
        let videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_DE.mp4';
        if(language === 'fr'){
            videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_FR.mp4'
        }

        return (
            <main className='main'>
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit"     style={{height: 700}}  loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/csm_Wirtschaftsverkehre_Relation_ad20f9b9de%20(1)_kYuxA69kF.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit"   style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/csm_Wirtschaftsverkehre_Relation_ad20f9b9de%20(1)_kYuxA69kF.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain"                      loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/csm_Wirtschaftsverkehre_Relation_ad20f9b9de%20(1)_kYuxA69kF.jpg?tr=w-700" alt="" />
                    </div>
                    <div className="big-img__text-block">
                        <h3 data-aos="fade-right" data-aos-delay="650" >{t('')}</h3>
                    </div>
                </section>
				<section className="title-section">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('Die einfachen $Schritte zur Steigerung der $Energieeffizienz in Ihrer $Stadt', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <SectionProjectSteps/>
				<RequestForm/>
				<SectionClients/>
				<SectionAdvantagesInformation/>
                <SectionDemo/>
            </main>
        )
    }
}

export default translate(CollaborationCompany);

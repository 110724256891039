'use strict'
import React from 'react';
import $ from 'jquery';
import AOS from 'aos';

import paths from '../../lib/paths';
import translate from "../translate";
import SectionHowMuchImpact from "../section-how-much-impact";
import logo from '../../assets/logo.svg';

import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import person from '../../assets/icon/product/person.svg';
import weather from '../../assets/icon/product/weather.svg';
import calendar from '../../assets/icon/product/calendar.svg';
import schema from '../../assets/icon/product/schema.svg';
import gateway from '../../assets/icon/product/gateway.svg';
import simple from '../../assets/icon/product/simple.svg';
import battery from '../../assets/icon/product/battery.svg';
import SectionDiscoverApplication from '../section-discover-application';
import SectionPricingInformation from '../section-pricing-information';
import SectionBookDemo from "../section-book-meeting";
import SectionDemo from "../section-demo";
import BookDemoModal from "../book-demo-modal";
import SectionAdvantagesInformation from "../section-advantages-information";
import SectionDiscoverPlattform from "../section-discover-plattform";


class Thermostat extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            openDemoModal: false
        }
    }

    componentDidMount() {
        let getParams = Object.fromEntries(new URLSearchParams(window.location.search));

        if(getParams.demoModalOpen === 'true'){
            this.setState({openDemoModal: true});
        }

        window.scroll(0, 0)
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        let offsetAOS = $(window).height();
        $('[data-aos-id="fixed"]').attr('data-aos-offset', offsetAOS);
        $('[data-aos="active"]').attr('data-aos-offset', (offsetAOS / 2));
    }

    render() {
        const {t} = this.props;
        let language = this.props.i18n.resolvedLanguage;
        let videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_DE.mp4';
        if(language === 'fr'){
            videoLink = 'https://cleveron-assets.s3.amazonaws.com/media/Cleveron_Sales_Animation_FR.mp4'
        }

        return (
            <main className='main'>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('Der clevere Thermostat mit $Präsenzerkennung für eine effiziente $Heizsteuerung', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block">
                        <img className="img img--big img-object-fit"     style={{height: 700}}  loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/My%20File%20Copy%20(9)_c9U9TbBZv.png?updatedAt=1714404110794?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit"   style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/My%20File%20Copy%20(9)_c9U9TbBZv.png?updatedAt=1714404110794?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain"                      loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/My%20File%20Copy%20(9)_c9U9TbBZv.png?updatedAt=1714404110794?tr=w-700" alt="" />
                    </div>
                    <div className="big-img__text-block">
                        <h3 data-aos="fade-right" data-aos-delay="650" >{t('')}</h3>
                    </div>
                </section>
                <section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row text-section__row--col-3">
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="50">
                                    <h4>{t('Integrierte Präsenzerfassung')}</h4>
                                    <div className="text-section__info">
                                        {t(`Intelligente Heizungssteuerung durch automatische Anwesenheitserkennung für gezieltes Heizen, wo und wann es erforderlich ist.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="150">
                                    <h4>{t('Keine Gateways erforderlich')}</h4>
                                    <div className="text-section__info">
                                        {t(`Die Internetverbindung ist direkt integriert und sofort einsatzbereit, ohne Installation oder Konfiguration von zusätzlichen Gateways.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                     data-aos-delay="300">
                                    <h4>{t('Überwachung der Luftqualität')}</h4>
                                    <div className="text-section__info">
                                        {t(`Dank hochwertiger integrierter Sensoren ist eine präzise Überwachung der Luftqualität in jedem Raum möglich.`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<section className="title-section">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('Unser intelligenter $Thermostat reduziert Ihren Energieverbrauch $sofort um $30% – einfach einzurichten und zu verwenden.', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
				<section className="message-section">
					<div className="message-section__row">
						<div className="img-block">
							<img className="img img--big"    src="https://ik.imagekit.io/sh/cleveron_ch/My%20File%20(1)%20(1)_0o-h7WyEz.png?tr=w-1600" loading="lazy" alt="" />
							<img className="img img--medium" src="https://ik.imagekit.io/sh/cleveron_ch/basic4x_4OhLFOc6B.jpg?tr=w-1400" loading="lazy" alt="" />
							<img className="img img--small"  src="https://ik.imagekit.io/sh/cleveron_ch/basic4x_4OhLFOc6B.jpg?tr=w-700"  loading="lazy" alt="" />
						</div>
						<div className="text-block">
							<div className="container">
                                        <h3>
                                            CLEVER Thermo
                                        </h3>
                                        <div className="text-block__info">
                                            {t(`Unser intelligenter Thermostat wurde speziell für den Einsatz in Nichtwohngebäuden entwickelt. Er verfügt über eine integrierter Präsenzerkennung sowie Sensoren zur Messung von Temperatur, CO2-Gehalt und Feuchtigkeit. Ein herausragendes Merkmal ist seine integrierte Internetverbindung, wodurch er ohne zusätzliches Gateway sofort einsatzbereit ist. Einfach einschalten und loslegen!`)}
                                        </div>
                                        <ul className="components__list">
                                            <li>
                                                {t('Einfache Installation ohne Fachkenntnisse')}
                                            </li>
											<li>
                                                {t('Integrierte wiederaufladbare Akkus')}
                                            </li>
                                            <li>
                                                {t('Nutzung der Wetterprognose')}
                                            </li>
                                        </ul>
                                        <a href={paths.links.thermoDatasheet} className="btn btn-green"
                                           style={{marginTop: "30px", fontSize: "24px"}}>
                                            {t('Technische Datenblatt')}
                                        </a>
                                    </div>
						</div>
					</div>
				</section>
				<div className="wrapper" id="del-fixed" data-aos-offset="0">
                    <h3 className="no-margin">
                        {
                            t('Die $Vorteile im Überblick')
                        }
                    </h3>
                </div>
				<section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row">
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={person} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Integrierte Präsenzerkennung')}</h4>
                                    <div className="text-section__info">
                                        {t(`Unser Thermostat erfasst automatisch die Anwesenheit von Personen in den Räumlichkeiten, um gezielt und effizient zu heizen, wenn es notwendig ist. Die gesammelten Daten ermöglichen es dem System, Gewohnheiten zu erlernen und den Raum im Voraus auf die gewünschte Temperatur zu bringen.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={gateway} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Keine Gateways erforderlich')}</h4>
                                    <div className="text-section__info">
                                        {t(`Keine Gateways erforderlich: Unser Thermostat benötigt keine zusätzlichen Gateways, um verbunden zu sein. Durch die integrierte Narrowband-Technologie und SIM-Karten müssen Sie ihn nur einschalten, und schon ist er betriebsbereit.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={wind} loading={'lazy'} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Überwachung der Luftqualität')}</h4>
                                    <div className="text-section__info">
                                        {t(`Dank hochwertiger integrierter Sensoren ist eine präzise Überwachung der Luftqualität in jedem Raum möglich. Die Ergebnisse werden durch eingebaute LEDs intuitiv dargestellt, sodass die Raumnutzer auf einen Blick erkennen können, wann sie richtig luften sollten.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={simple} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Einfache Installation')}</h4>
                                    <div className="text-section__info">
                                        {t(`Entfernen Sie einfach den alten Thermostat und ordnen Sie den neuen von Cleveron auf der Plattform dem Raum zu. Anschließend schrauben Sie ihn auf und schalten ihn ein. So einfach ist die Installation des neuen Thermostats. Das Beste daran: Es sind keine baulichen Maßnahmen erforderlich.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={battery} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Wiederaufladbarer Akku')}</h4>
                                    <div className="text-section__info">
                                        {t(`Dank der integrierten wiederaufladbaren Akkus auch in diesem Bereich keine Kompromisse bei der Nachhaltigkeit. Eine einfach Aufladung alle 2 bis 3 Jahre genügt um signifikante Einsparungen zu realisieren.`)}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box" data-aos="fade-left" data-aos-offset='-10'>
                                    <img src={weather} alt="" className="text-section__icon" />
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Nutzung der Wettervorhersage')}</h4>
                                    <div className="text-section__info">
                                        {t(`Eine angenehme Raumtemperatur kann nur erreicht werden, wenn sich der Raum je nach Bedarf unterschiedlich stark erwärmt. Unser Thermostat bezieht deshalb die Wettervorhersage in seine Berechnungen mit ein.`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<SectionDiscoverPlattform/>
				<section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <div className="logo sidebar-section__logo">
                                    <img src={logo} loading={'lazy'} alt="" className="logo__img" />
                                </div>
                                <img className="sidebar-section__client-logo" loading={'lazy'} alt=""/>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <h3>
                                            {t('Der einzige $clevere Thermostat ohne $Gateway')}
                                        </h3>
                                        <p>
                                            {t('Unser Thermostat nutzt die NB-IoT-Technologie für eine zuverlässige Internetverbindung. Narrowband IoT (NB-IoT) ist ein stromsparendes Mobilfunknetz, speziell für das Internet der Dinge. Es beansprucht schmalere Bandbreiten als herkömmliche Netze, kann aber dank Wide Area-Technologie mehr Geräte und Fläche pro Zelle abdecken.')}
                                        </p>
                                        <a href="https://swisscom.cleveron.ch/" target={'_blank'} className="btn">{t('Erfahren Sie mehr')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				
                <SectionDemo/>

            </main>
        )
    }
}

export default translate(Thermostat);

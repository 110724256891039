'use strict';

import React from 'react';
import AOS from 'aos';
// import ReactPlayer from 'react-player'


import $ from "jquery";
import translate from "../translate";
import paths from "../../lib/paths";
import SectionHowMuchImpact from "../section-how-much-impact";
import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import SectionComponent30Percent from "../section-component-30-percent";
import SectionDemo from "../section-demo";
import SectionPricingInformation from "../section-pricing-information";

class AirQuality extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scroll(0, 0)
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        let offsetAOS = $(window).height();
        $('[data-aos-id="fixed"]').attr('data-aos-offset', offsetAOS);
        $('[data-aos="active"]').attr('data-aos-offset', (offsetAOS / 2));
    }

    render() {
        const {t} = this.props;
        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {t('Verbessern Sie das $Raumklima mit optimierter $Luftqualität und $nachhaltigem Lüften', {delay: true})}
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="big-img">
                    <div className="big-img__img-block big-img__img-block--reverse">
                        <img className="img img--big img-object-fit" style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-img4x_4STpZirGW.jpg?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit" style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-img4x_4STpZirGW.jpg?tr=w-1400" alt="" />
                        <img className="img img--small img-object-contain" loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/full-img4x_4STpZirGW.jpg?tr=w-700" alt="" />
                    </div>
                </section>
                <section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h4 data-aos="fade-up" data-aos-offset='-10' data-aos-delay="50">{t('Der CO2-Gehalt in Konferenzräumen, Büros und Klassenzimmern kann die kognitiven Fähigkeiten um mehr als 50 % reduzieren. Effizientes und nachhaltiges Lüften macht die Räume zu gesundheitsfördernden und produktiven Orten')}</h4>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="150">
                                            <div className="text-section__info">
                                                {t('Gemäss dem Harvard Business Review sind 57% aller krankheitsbedingten Fehlzeiten auf eine schlechte Belüftung der Büros zurückzuführen. Dank unserer Technologie ist es möglich, dieses Phänomen zu bekämpfen.')}
                                            </div>
                                        </div>
                                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="300">
                                            <div className="text-section__info">
                                                {t('Erkältungen, unterschiedliches Temperaturempfinden und Aktivitäten führen dazu, dass das Lüften nicht nach dem subjektiven Empfinden ausgerichtet werden kann. Gerade im Winter gestaltet sich die Diskussion um die richtige Belüftung mit mehr als 10 Personen meist sehr komplex, wogegen der CLEVER Sense nun Abhilfe schafft und mit einem Farbwechsel auf grün anzeigt, wann die Fenster wieder geschlossen werden dürfen, damit die Raumtemperatur konstant bleibt und kein unverhältnismässiger Wärmeverlust droht.')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="title-section">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('Dank Plug & Play in nur einem Tag installier')
                                }
                            </h2>
                        </div>
                    </div>
                </section>
                <section className="message-section">
					<div className="message-section__row">
						<div className="img-block">
							<img className="img img--big"    src="https://ik.imagekit.io/sh/cleveron_ch/conponent-2-2x_DGQmIegQJ.jpg?tr=w-1600" loading="lazy" alt="" />
							<img className="img img--medium" src="https://ik.imagekit.io/sh/cleveron_ch/conponent-2-2x_DGQmIegQJ.jpg?tr=w-1400" loading="lazy" alt="" />
							<img className="img img--small"  src="https://ik.imagekit.io/sh/cleveron_ch/conponent-2-2x_DGQmIegQJ.jpg?tr=w-700"  loading="lazy" alt="" />
						</div>
						<div className="text-block">
							<div className="container">
                                        <h3>
                                            CLEVER Sense
                                        </h3>
                                        <div className="text-block__info">
                                            {t(`Dank der im Sensor integrierten LED-Beleuchtung kann der Benutzer die aktuelle Luftqualität leicht ablesen und entsprechend lüften. Eine gute Luftqualität ist in allen Gebäuden wichtig. Unabhängig vom Alter oder vom Gebäudetyp. CLEVER Sense eignet sich daher nicht nur für ältere Gebäude, sondern auch für sanierte Liegenschaften oder Neubauten.`)}
                                        </div>
                                        <ul className="components__list">
                                            <li>
                                                {t('Hochwertige Messung von Temperatur, Luftfeuchtigkeit')}
                                            </li>
											<li>
                                                {t('Zentralisierte und benutzerfreundliche Datenauswertung')}
                                            </li>
                                            <li>
                                                {t('Installierbar in einem Tag, ohne bauliche Maßnahmen')}
                                            </li>
                                        </ul>
                                        <a href={paths.links.senseDatasheet} className="btn btn-green"
                                           style={{marginTop: "30px", fontSize: "24px"}}>
                                            {t('Technische Datenblatt')}
                                        </a>
                                    </div>
						</div>
					</div>
				</section>
                <SectionDemo/>
                <SectionPricingInformation />
                <div  id="del-fixed" className="wrapper" data-aos-id="del-fixed" data-aos="del-fixed" data-aos-offset="0" style={{marginTop: 60}}>
                    <h3 className="no-margin">
                        {t('Die $Vorteile im Überblick')}
                    </h3>
                </div>
                <section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row">
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={phone} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Bedienung')}</h4>
                                    <div className="text-section__info">
                                        {t('Farben und Grenzwerte können in der Webapp benutzerspezifisch angepasst werden.')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={graphic} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Monatlicher Report')}</h4>
                                    <div className="text-section__info">
                                        {t('Sie behalten die Luftqualität und den Energieverbrauch in Ihren Räumen auch langfristig im Auge')}
                                    </div>
                                </div>
                            </div>

                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={wind} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Übersicht')}</h4>
                                    <div className="text-section__info">
                                        {t('Dank LED-Anzeige und akustischem Signal sind Sie immer über den Status der Luftqualität informiert')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={temperature} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Messdaten')}</h4>
                                    <div className="text-section__info">
                                        {t('Um einen Luftqualitätswert von max. 1500ppm zu gewährleisten, werden die Temperatur, die Luftfeuchtigkeit und sonstige organische Luftbestandteile gemessen, um die Belüftung dementsprechend zu regulieren')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="video-section">
                    <div className="video-section__row">
                        <div className="video-section__text">
                            <h5>CLEVER Sense</h5>
                            <div className="video-section__info">
                                {t('Die Schweizer Innovation für bessere Luftqualität. Einfache Installation für sofortige Kontrolle dank praktischer LED-Anzeige')}
                            </div>
                            <a onClick={() => this.props.history.push('/news/wie-45-clever-sense-in-der-wessenberg-schule-konstanz-die-raumluftqualitat-auch-nach-der-pandemie-im')} className="btn">{t('Entdecken Sie ein Praxisbeispiel')}</a>
                        </div>
                        <div className="video-section__video">
                            <div className="video">
                                <video controls style={{
                                    width: '100%',
                                    maxHeight: 700
                                }}
                                       poster={'https://ik.imagekit.io/sh/cleveron_ch/Sense_Info_Blog_Uu84G5WyUN.jpg?tr=w-1400'}
                                >
                                    <source
                                        src={'https://cleveron-assets.s3.amazonaws.com/media/POST_ITW_SWEN.mp4'}
                                        type="video/mp4"/>
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default translate(AirQuality);
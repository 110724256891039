'use strict';

import React, {Fragment} from 'react';
import PropType from 'prop-types';

import translate from '../translate';

class NotFound extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
    }

    render() {
        const {t} = this.props;

        return  <div className="">
            <div className="row">
                <div className="col-xs-12 text-center">
                    <h2 style={{marginTop: 200}}>{t("Not found")}</h2>
                </div>
            </div>
        </div>;
    }
}

NotFound.propTypes = {
    t: PropType.func
};

export default translate(NotFound);


'use strict'

import React from 'react';
import AOS from 'aos';

import translate from "../translate";
import ContactCTA from '../contactCTA';
import paths from '../../lib/paths';
import SectionComponent30Percent from "../section-component-30-percent";
import SectionDiscoverApplication from "../section-discover-application";
import SectionBookDemo from "../section-book-meeting";
import SectionDemo from "../section-demo";
import phone from '../../assets/icon/product/phone.svg';
import graphic from '../../assets/icon/product/graphic.svg';
import wind from '../../assets/icon/product/wind.svg';
import temperature from '../../assets/icon/product/temperature.svg';
import airQuality from '../../assets/icon/product/air-quality.svg';
import roi from '../../assets/icon/corporate/icon_roi.svg';
import money from '../../assets/icon/corporate/money.svg';
import nature from '../../assets/icon/corporate/nature.svg';
import smart from '../../assets/icon/corporate/smart-building.svg';
import analytics from '../../assets/icon/corporate/analytics.svg';
import SectionPricingInformation from "../section-pricing-information";
import SectionAdvantagesInformation from "../section-advantages-information";
import SectionClients from "../section-clients";
import BookDemoModal from "../book-demo-modal";
class Home extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            openDemoModal: false
        }
    }

    componentDidMount() {
        AOS.init({
            duration: 2000,
            delay: 1000
        });
        let getParams = Object.fromEntries(new URLSearchParams(window.location.search));

        if(getParams.demoModalOpen === 'true'){
            this.setState({openDemoModal: true});
        }
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;

        let isIE = false || !!document.documentMode;

        let language = this.props.i18n.resolvedLanguage;
        return (
            <>
                <section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h2>
                                {
                                    t('Das $clevere System, dass die $Energiekosten und den CO2-Ausstoß Ihrer Liegenschaten $sofort und $nachhtaltig verbessert.', {delay: true})
                                }
                            </h2>
                        </div>
                    </div>
                </section>
				
				<section className="big-img">
                    <div className="big-img__img-block ">
                        <img className="img img--big img-object-fit" style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/DSC01636-min%201_evC-lkQXm.png?tr=w-1600" alt="" />
                        <img className="img img--medium img-object-fit" style={{height: 700}} loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/DSC01636-min%201_evC-lkQXm.png?tr=w-1400" alt="" />
                        <img className="img img--small img-object-fit" loading={'lazy'} src="https://ik.imagekit.io/sh/cleveron_ch/DSC01636-min%201_evC-lkQXm.png?tr=w-700" alt="" />
                    </div>
                </section>
				<section className="sidebar-section">
                    <div className="wrapper">
                        <div className="sidebar-section__row">
                            <div className="sidebar-section__sidebar">
                                <h4>{t('Fit für das Gebäudeenergiegesetz')}</h4>
                            </div>
                            <div className="sidebar-section__content">
                                <div className="sidebar-section__row">
                                    <div className="sidebar-section__col">
                                        <div className="text-section__info-box">
                                            <div className="text-section__info">
                                                {t('Das Gebäudeenergiegesetz 2024 (GEG) verpflichtet Eigentümer von Gebäuden mit einem Energieverbrauch von mehr als 290 kW zur Installation von intelligenten Heizsystemen mit Einzelraumregelung*. Cleverons System bietet eine gesetzeskonforme Lösung für Eigentümer von Bestandsgebäuden aller Art. Die Thermostate verfügen über ein Präsenzerfassungssystem, das einen Automatisierungsgrad A ermöglicht. Dadurch verbessern Sie nicht nur Ihren CO2-Ausstoß und Ihr ESG-Rating, sondern sparen direkt Geld bei den Energiekosten ein.')}
                                            </div>
                                        </div>
                                        <div className="text-section__info-box" data-aos="fade-up" data-aos-offset='-10'
                                             data-aos-delay="300">
                                            <div className="text-section__info">
                                                {t('*Gesetzlichen Anforderungen des GEG 2024 (Gebäudeenergiegesetz) an die Gebäudeautomation, Prof. Dr. Michael Krödel, Institut für Gebäudetechnologie')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<div  id="del-fixed" className="wrapper" data-aos-id="del-fixed" data-aos="del-fixed" data-aos-offset="0" style={{marginTop: 60}}>
                    <h3 className="no-margin">
                        {t('Die $Vorteile für Sie und Ihre $Immobilien')}
                    </h3>
                </div>
				<section className="text-section">
                    <div className="wrapper">
                        <div className="text-section__row">
							<div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={money} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Senkung der Energiekosten')}</h4>
                                    <div className="text-section__info">
                                        {t('Reduzieren Sie Ihren CO2-Ausstoss von Ihren Gebäuden um bis zu 30%.')}
                                    </div>
                                </div>
                            </div>
                            <div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={roi} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Schnelle Amortisation')}</h4>
                                    <div className="text-section__info">
                                        {t('Das System lässt sich ohne bauliche Veränderungen und mit minimalen Kosten installieren. Dadurch amortisiert es sich innerhalb kürzester Zeit.')}
                                    </div>
                                </div>
                            </div>
							<div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={graphic} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Verbesserung ESG-Rating')}</h4>
                                    <div className="text-section__info">
                                        {t('Erhöhen Sie Ihr ESG-Rating und das Ihrer Kunden durch die Reduzierung von CO2-Emissionen und die Optimierung Ihres Gebäudeportfolios.')}
                                    </div>
                                </div>
                            </div>
							<div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={nature} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Reduzierung des CO2-Ausstoß')}</h4>
                                    <div className="text-section__info">
                                        {t('Das Cleveron System reduziert den CO2-Ausstoß Ihrer Nichtwohngebäude ab dem ersten Tag.')}
                                    </div>
                                </div>
                            </div>
							
							<div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={smart} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Gesetzeskonformität gemäss GEG')}</h4>
                                    <div className="text-section__info">
                                        {t('Das neue Gebäudeenergiegesetz (GEG) verlangt von Liegenschaftseigentümern die Installation eines intelligenten Heizsystems.')}
                                    </div>
                                </div>
                            </div>
							<div className="text-section__item">
                                <div className="text-section__icon-box">
                                    <img src={analytics} loading={'lazy'} alt="" className="text-section__icon"/>
                                </div>
                                <div className="text-section__info-box">
                                    <h4 className="decor-color">{t('Gebäudedaten erfassen')}</h4>
                                    <div className="text-section__info">
                                        {t('Erheben und verwalten Sie Ihre Daten zur Gebäudenutzung und Energieverbauch.')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
				<SectionAdvantagesInformation />
				<section className="title-section first-screen">
                    <div className="wrapper">
                        <div className="title-section__main-info">
                            <h3>
                                {
                                    t('Ein modulares $Plug & Play-System zur Optimierung der $Heizungssteuerung in Nichtwohngebäuden. $Flexibel, einfach zu installieren und sofort $einsatzbereit.', {delay: false})
                                }
                            </h3>
                        </div>
                    </div>
                </section>
				<section className='product-section'>
                        <div id='product-section' className='product-section__row'>
							<div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/My%20File%20Copy%20(9)_c9U9TbBZv.png?updatedAt=1714404110794?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/air-quality-small4x_3mRA6LD3X.jpg?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="left" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.openMobDescription('left')}>
                                            {t('Clevere Thermostate')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Unsere intelligenten Thermostate sind mit integrierter Präsenzerkennung und Luftqualitätssensoren ausgestattet, um höchsten Komfort und Effizienz zu gewährleisten.`)}
                                        </div>
                                        <a
                                            onClick={() => this.props.history.push(paths.thermostat, 'left')}
                                            className='btn product-section__link'
                                        >
                                            {t('Erfahren Sie mehr')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='product-section__box'>
                                <div className='product-section__img-block'>
                                    <img className='product-section__img img img--big'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1600'
                                         alt='' />
                                    <img className='product-section__img img img--medium'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-1400'
                                         alt=''
                                    />
                                    <img className='product-section__img img img--small'
                                         loading={'lazy'}
                                         src='https://ik.imagekit.io/sh/cleveron_ch/full-system-small4x_PmFMzBIb6.jpg?tr=w-700' alt=''
                                    />
                                </div>
                                <div id="right" className='product-section__description'>
                                    <div className='container'>
                                        <h3 className='product-section__title' onClick={() => this.openMobDescription('right')}>
                                            {t('Cloudbasiert Plattform')}
                                        </h3>
                                        <div className='product-section__info'>
                                            {t(`Unsere intuitive Online-Plattform ermöglicht die zentrale Steuerung des Systems, automatisiert den Gebäudeunterhalt und erfasst Nutzungsdaten effizient.`)}
                                        </div>
                                        <a
                                            onClick={() => this.props.history.push(paths.plattform, 'right')}
                                            className='btn product-section__link'
                                        >
                                            {t('Erfahren Sie mehr')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </section>
                    <div  id="del-fixed" className="wrapper" data-aos-id="del-fixed" data-aos="del-fixed" data-aos-offset="0" style={{marginTop: 60}}>
                    <h3 className="no-margin">
                    </h3>
                </div>
				<SectionClients/>
				<SectionDemo opened={this.state.openDemoModal} onClose={() => this.setState({openDemoModal: false})}/>
                <BookDemoModal opened={this.state.openDemoModal} onClose={() => this.setState({openDemoModal: false})} />
            </>
        )
    }
}

export default translate(Home);

'use strict';

import React from 'react';

import translate from "../translate";
import paths from '../../lib/paths'
import BookDemoModal from '../book-demo-modal';
import BookCallIframeModal from "../book-call-iframe-modal";

class SectionResultCalc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDemoModal: false,
            openCallModal: false
        }
    }

    render() {
        const {t} = this.props;

        let language = this.props.i18n.resolvedLanguage;
        let bookingLink = 'https://cleveron.zohobookings.eu/#/67150000003061016';
        if(language === 'it'){
            bookingLink = 'https://cleveron.zohobookings.eu/#/customer/67150000000341244';
        }

        return (
            <section className="basic-section section-demo-container">
                <div className="section-demo-wrapper">
                    <div className="section-demo">
                        <div className="section-demo-item">
                            <h2 className="section-demo-item-title">
                                {t(`Sprechen Sie mit unseren Beratern`)}
                            </h2>
                            <a
                               onClick={() => this.setState({openCallModal: true})}
                               className="btn btn-green section-demo-item-button">
                                {t('Termin vereinbaren')}
                            </a>
                            <BookCallIframeModal opened={this.state.openCallModal} onClose={() => this.setState({openCallModal: false})}/>
                        </div>
                        <div className="section-demo-item section-demo-item-savings">
                            <h2 className="section-demo-item-title">
                                {t(`Berechnen Sie Ihre Ersparnisse`)}
                            </h2>
                            <a href={paths.demoRequest} className="btn btn-green section-demo-item-button">
                                {t('Jetzt online berechnen')}
                            </a>
                        </div>
                        <div className="section-demo-item">
                            <h2 className="section-demo-item-title">
                                {t(`Testen Sie unsere Plattform`)}
                            </h2>
                            <a onClick={() => this.setState({openDemoModal: true})}  className="btn btn-green section-demo-item-button">
                                {t('Sofort ausprobieren')}
                            </a>
                        </div>
                    </div>
                </div>
                <BookDemoModal opened={this.state.openDemoModal} onClose={() => this.setState({openDemoModal: false})} />
            </section>
        )
    }
}

export default translate(SectionResultCalc);

'use strict'

import React from 'react';

import translate from "../translate";

class PageDataProtection extends React.Component{
    constructor(props) {
        super(props);
    }

    openDescriptionBlock(id) {
        const element = document.getElementById(id);

        if(element.classList.contains('active')) {
            element.classList.remove('active')
        } else {
            element.classList.add('active')
        }
    }

    render() {
        const {t} = this.props;


        let array = new Array(10).fill(null);

        return (
            <>
                <section className='faq'>
                    <div className='wrapper'>
                        <h3>
                            {t('Privacy policy')}
                        </h3>
                        <ul className='faq__list'>
                            {
                                array.map((_, i) => {
                                    return <li id={`agb-${i}`} className='faq__item'>
                                        <h4 className='faq__quastion'>{t(`page-6-label-${i}`)}</h4>
                                        <div className='faq__answer'>
                                            {t(`page-6-label-${i}`)}
                                        </div>
                                        <div className='faq__open-btn' onClick={() => this.openDescriptionBlock(`privacy-policy-${i}`)}>
                                            <span className='faq__btn-line' />
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                </section>
            </>
        )
    }
}

export default translate(PageDataProtection);

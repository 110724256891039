import React from 'react';

class SectionComponent30Percent extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        const t = this.props.t;

        return <section className="message-section">
            <div className="message-section__row">
                <div className="img-block">
                    <img className="img img--big"    src="https://ik.imagekit.io/sh/cleveron_ch/basic4x_4OhLFOc6B.jpg?tr=w-1600" loading="lazy" alt="" />
                    <img className="img img--medium" src="https://ik.imagekit.io/sh/cleveron_ch/basic4x_4OhLFOc6B.jpg?tr=w-1400" loading="lazy" alt="" />
                    <img className="img img--small"  src="https://ik.imagekit.io/sh/cleveron_ch/basic4x_4OhLFOc6B.jpg?tr=w-700"  loading="lazy" alt="" />
                </div>
                <div className="text-block">
                    <div className="container">
                        <h3>
                            {t(`page-1-label-8`)}
                        </h3>
                        <a href="/heating" className="btn">{t('page-1-label-9')}</a>
                    </div>
                </div>
            </div>
        </section>
    }
}

export default SectionComponent30Percent;
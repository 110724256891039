'use strict';

import React from 'react';
import swal from 'sweetalert';
import Parse from 'parse';
import paths from '../../lib/paths'

import translate from '../translate';

const isValidEmail = require('is-valid-email');

class RequestForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                name: '',
                email: '',
                comment: '',
                company: '',
                phone: '',
                privacy: false
            },
            errors: {
                name: false,
                email: false,
                phone: false,
                company: false,
                privacy: false
            }
        }
        this.sendForm = this.sendForm.bind(this);
    }

    componentDidMount() {
        document.getElementById('partnership-form').onSubmit = function () {
            return this.sendForm();
        };
    }

    inputValues(event, field) {
        let value = event.target.value;

        if (field === 'phone') {
            value = value.replace(/\D/g,'');
        }

        this.setState(prev => {
            prev.form[field] = value;

            prev.errors[field] = false;

            return prev;
        });
    }

    async sendForm(e) {
        const {form: {email, company, phone, comment, name, privacy}} = this.state;
        const {t} = this.props;

        this.setState({
            form: {...this.state.form},
            errors: {
                name: !name,
                email: !email || !isValidEmail(email),
                company: !company,
                phone: !phone,
                isValidEmail: !isValidEmail(email),
                privacy: !privacy
            }
        });

        if (!(name && email && company && phone && isValidEmail(email))) {
            e.preventDefault();
            return
        }

        try {
            let token = await grecaptcha.execute('6LcHZqUZAAAAAAB9FgyLwilIjZAk8YRT23Uo0OH4', {action: 'submit'});

            let content = `${comment}\n\nTel: ${phone}\n`;
            await Parse.Cloud.run('send-email', {
                mail: email,
                name,
                subject: company,
                comment: content,
                privacy: privacy,
                token
            });

            this.setState({
                form: {
                    name: '',
                    company: '',
                    phone: '',
                    email: '',
                    comment: '',
                    privacy:''
                },
                errors: {
                    name: false,
                    email: false,
                    company: false,
                    phone: false,
                    privacy: false
                }
            });

            swal(t('component-9-label-13'), t('component-9-label-12'), 'success');
        } catch (e) {
            swal('Error', e.message, 'error');
        }
    }

    


    render() {
        const {errors, form: {name, email, phone, company, comment, privacy}} = this.state;
        const {t} = this.props;

        return (

            <section className="basic-section partnetship-form-section" style={{marginBottom: 60}}>
                <div className="basic-section__row">
                    <div className="text-block">
                        <div className="container">
                            <div>
                                <h3>
                                    {t('Werden Sie jetzt unser Partner')}
                                </h3>
                            </div>
                            <div className="basic-section__row">
                                <div className='partnership-form-description'>
                                    {t('Wir sind auf der Suche nach Partnern in ganz Europa, die nach effektiven Energiesparlösungen für ihre Kunden suchen.')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-block">
                        <div className="container">
                            <form
                                id='partnership-form'
                                onSubmit={this.sendForm}
                                className="form"
                            >
                                <fieldset>
                                    <label className={`form__label ${errors.name ? 'error' : ''}`} data-label=""
                                           data-error={t('Name')}>
                                        <input
                                            name="name"
                                            type="text"
                                            className="form__input"
                                            placeholder={t('Name')}
                                            onChange={(e) => this.inputValues(e, 'name')}
                                            value={name}
                                        />
                                    </label>
                                    <label className={`form__label ${errors.company ? 'error' : ''}`} data-label=""
                                           data-error={t('Unternehmen/Stadt')}>
                                        <input
                                            name="company"
                                            type="text"
                                            className="form__input"
                                            placeholder={t('Unternehmen')}
                                            onChange={(e) => this.inputValues(e, 'company')}
                                            value={company}
                                        />
                                    </label>
                                    <label
                                        className={`form__label ${errors.email ? 'error' : ''}`}
                                        data-error={t('E-Mail')}>
                                        <input
                                            name="mail"
                                            type="email"
                                            className="form__input"
                                            placeholder={t('E-Mail')}
                                            value={email}
                                            onChange={(e) => this.inputValues(e, 'email')}
                                        />
                                    </label>
                                    <label className={`form__label ${errors.phone ? 'error' : ''}`} data-label=""
                                           data-error={t('Telephone')}>
                                        <input
                                            type="text"
                                            name="phone"
                                            className="form__input"
                                            placeholder={t('Telephone')}
                                            value={phone}
                                            onChange={(e) => this.inputValues(e, 'phone')}
                                        />
                                    </label>
                                    <label className="form__label" data-label="">
                                            <textarea
                                                name="comment"
                                                cols="30"
                                                rows="10"
                                                value={comment}
                                                placeholder={t('Kommentar')}
                                                onChange={(e) => this.inputValues(e, 'comment')}
                                            />
                                    </label>
                                    <label className={`form__checkbox-label ${errors.privacy ? 'error' : ''}`} data-label=""
                                           data-error={t('privacy')}>
                                    <input
                                        type="checkbox"
                                        checked={privacy}
                                        onChange={() => {}}
                                        onClick={(e) => this.inputValues(e, 'privacy')}
                                    />
                                    <div className="form__checkbox-label-info" style={{marginBottom: 40}}>
                                        <div className="form__checkbox-square" />
                                        <div className="form__checkbox-info">
                                            {t('Ich akzeptiere die Datenschutzbestimmungen der Cleveron Europe GmbH')}
                                        </div>
                                    </div>
                                </label>
                                    <label className="form__submit" >
                                        <button type="button"
                                                className='partnetship-form-section-btn'
                                                onClick={this.sendForm}>{t('Jetzt kontakt aufnehmen')} </button>
                                    </label>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default translate(RequestForm);
'use strict';

import React from 'react';
import PropTypes from "prop-types";

import translate from '../translate';
import paths from '../../lib/paths'
import arrowIcon from '../../assets/icon/arrow-white.svg';
import BookDemoModal from '../book-demo-modal';

class SectionBookMeeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDemoModal: false
        }
    }

    render() {
        const {t} = this.props;

        let language = this.props.i18n.resolvedLanguage;

        let link;
        if(language === 'it'){
            link = this.props.itLink || this.props.deLink;
        } else if(language === 'fr'){
            link = this.props.frLink || this.props.deLink;
        } else if(language === 'en'){
            link = this.props.enLink || this.props.deLink;
        } else {
            link = this.props.deLink;
        }

        return (
            <section className="basic-section section-book-demo-container">
                <div className="section-book-demo-wrapper">
                    <div className="section-book-demo">
                        <h2 className="section-book-demo-item-title">
                            {t(`component-11-label-1`)}
                        </h2>
                        <a href={link} target={"_blank"} className="section-book-demo-button">
                            {t('component-11-label-2')}
                        </a>
                    </div>
                </div>
            </section>
        );
    }
}



SectionBookMeeting.propTypes = {
    itLink: PropTypes.string,
    deLink: PropTypes.string,
    frLink: PropTypes.string,
    enLink: PropTypes.string.isRequired
};


export default translate(SectionBookMeeting);
'use strict';

import React from 'react';
import AOS from "aos";
import $ from "jquery";
import slick from 'slick-carousel';

import translate from "../translate";
import ContactCTA from "../contactCTA";

class Video extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration: 1500,
            delay: 1000
        });

        let mediaSlider = $('.media__slider');

        mediaSlider.slick({
            speed: 800,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: $('.arrows__item--prev'),
            nextArrow: $('.arrows__item--next'),
            responsive: [
                {
                    breakpoint: 1370,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 770,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }

    render() {
        const {t} = this.props;
        return (
            <main className="main">
                <section className="sidebar-section bg-gray">
                    <div className="wrapper">
                        <>
                            <div className="sidebar-section__row">
                                <div className="sidebar-section__sidebar">
                                    <h3>{t('Title')}</h3>
                                </div>
                                <div className="sidebar-section__content">
                                    <div className="video" data-aos="fade-right">
                                        <a className="video__link" href="https://youtu.be/neHA4MJwpnY">
                                            <img className="video__media"
                                                 loading={'lazy'}
                                                 src="https://i1.ytimg.com/vi/CTyNTpol05k/hqdefault.jpg"
                                                 alt="cleveron" />
                                        </a>
                                        <button className="video__button" aria-label="Запустить видео">
                                            <svg width="68" height="48" viewBox="0 0 68 48">
                                                <path className="video__button-shape"
                                                      d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z">
                                                </path>
                                                <path className="video__button-icon" d="M 45,24 27,14 27,34" />
                                            </svg>
                                        </button>
                                    </div>
                                    <p data-aos="fade-up" data-aos-offset='-10' data-aos-delay="50">
                                        {t(`enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla`)}
                                    </p>
                                </div>
                            </div>
                        </>
                    </div>
                </section>
                <section className="sidebar-section bg-gray">
                    <div className="wrapper">
                        <>
                            <div className="sidebar-section__row">
                                <div className="sidebar-section__sidebar">
                                    <h3>Video</h3>
                                    <div className="arrows">
                                        <div className="arrows__item arrows__item--prev">
                                            <img className="arrows__arrow" src="/assets/icon/arrow.svg" loading={'lazy'} alt="" />
                                        </div>
                                        <div className="arrows__item arrows__item--next">
                                            <img className="arrows__arrow" src="/assets/icon/arrow.svg" loading={'lazy'} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar-section__content media">
                                    <div className="media__slider">
                                        <div>
                                            <div>
                                                <div className="media__slider-item">
                                                    <a href="news.html"
                                                       className="media__img-link media__img-link--video">
                                                        <img src="/assets/contact/1.jpg" loading={'lazy'}  alt="" className="media__img" />
                                                    </a>
                                                    <div className="media__title-box">
                                                        <div className="media__tag">
                                                            {t('Sport')}
                                                        </div>
                                                        <a href="" className="h4 media__link">
                                                            {t('Facilitate team ending final match in 30 sec')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="media__slider-item">
                                                    <a href="news.html"
                                                       className="media__img-link media__img-link--video">
                                                        <img src="/assets/contact/2.jpg" loading={'lazy'}  alt="" className="media__img" />
                                                    </a>
                                                    <div className="media__title-box">
                                                        <div className="media__tag">
                                                            {t('Sport')}
                                                        </div>

                                                        <a href="" className="h4 media__link">
                                                            {t('Facilitate team ending final match in 30 sec')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="media__slider-item">
                                                    <a href="news.html"
                                                       className="media__img-link media__img-link--video">
                                                        <img src="/assets/contact/3.jpg" loading={'lazy'}  alt="" className="media__img" />
                                                    </a>
                                                    <div className="media__title-box">
                                                        <div className="media__tag">
                                                            {t('Sport')}
                                                        </div>
                                                        <a href="" className="h4 media__link">
                                                            {t('Facilitate team ending final match in 30 sec')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <div className="media__slider-item">
                                                    <a href="news.html"
                                                       className="media__img-link media__img-link--video">
                                                        <img src="/assets/contact/4.jpg" loading={'lazy'}  alt="" className="media__img" />
                                                    </a>
                                                    <div className="media__title-box">
                                                        <div className="media__tag">
                                                            {t('Sport')}
                                                        </div>
                                                        <a href="" className="h4 media__link">
                                                            {t('Facilitate team ending final match in 30 sec')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </section>
                <ContactCTA />
            </main>
        );
    }
}

export default translate(Video);
